import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/vacationPlanEmployees/types";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/hr/routes";
import router from "@/router/hr";
import routeTypes from "@/store/shared/route/types";
import { first } from "lodash";
import { formatFullName } from "@/utils/formatting";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import VacationPlanEmployeesState from "@/store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesState";
import VacationPlanEmployeesRouteParams from "@/store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesRouteParams";
import VacationPlanEmployeesRouteQuery from "@/store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesRouteQuery";
import VacationPlanEmployeesRouteService from "@/store/hr/modules/vacationPlanEmployees/services/vacationPlanEmployeesRouteService";
import VacationPlanEmployeesFilter from "@/store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesFilter";
import { HrController } from "@/api/hr";
import { HrUpdateEmployeeVacationDaysRequestService } from "@/types/hr/vacation/hrUpdateEmployeeVacationDays";
import { HrImportEmployeesRequestService } from "@/types/hr/vacation/hrImportEmployeesRequest";
import { HrImportEmployeesResponseService } from "@/types/hr/vacation/hrImportEmployeesResponse";
import { ApiHrImportEmployeesRequestMapper } from "@/api/hr/types/vacation/apiHrImportEmployeesRequest";
import { ApiHrUpdateEmployeeVacationDaysMapper } from "@/api/hr/types/vacation/apiHrUpdateEmployeeVacationDaysRequest";
import { HrAdministrationEmployeesItem, HrAdministrationEmployeesItemService } from "@/types/hr/vacation/hrAdministrationEmployeesItem";
import { ApiHrGetEmployeesParameters } from "@/api/hr/types/apiHrGetEmployeesParameters";
import VacationPlanEmployeesMapper from "@/store/hr/modules/vacationPlanEmployees/mapper";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { getHrDefaultYear } from "@/views/hr/helpers/getHrDefaultYear";

const abortService = new AbortService();
const hrController = new HrController(abortService);

const defaultRouteQuery = new VacationPlanEmployeesRouteQuery();
const defaultRouteParams = new VacationPlanEmployeesRouteParams();

const routeService = new VacationPlanEmployeesRouteService(defaultRouteQuery, defaultRouteParams);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<VacationPlanEmployeesState>()).build();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["updateVacationDaysRequest"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new VacationPlanEmployeesState(
			new ListingModel<HrAdministrationEmployeesItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<string>({
				type: defaultRouteQuery.sort,
				order: defaultRouteQuery.sortDirection
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			new VacationPlanEmployeesFilter(),
			routeMixin.state(),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<VacationPlanEmployeesState>;

const getters = <GetterTree<VacationPlanEmployeesState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: (state) => {
		return state.listing.items.map(x => ({
			...x,
			employmentDate: x.employmentDate && formatDate(x.employmentDate, dateFormat),
			fullName: formatFullName(x)
		}));
	},
	[getterTypes.yearValues](state) {
		return state.years.map(x => x.value);
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<VacationPlanEmployeesState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<VacationPlanEmployeesState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !mutation.payload.to.query.year) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.reconstituteRoute));
			} else if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
			break;
		case resolveMutation(namespace, mutationTypes.SET_SORTING_TYPE):
		case resolveMutation(namespace, mutationTypes.SET_SORTING_ORDER):
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_YEAR):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_DEPARTMENT_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_EMPLOYEE_IDS):
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
				
				updateListingBatchService.push(async () => {
					await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
				});
			}
			
			break;
		}
	}
};

const actions = <ActionTree<VacationPlanEmployeesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.processRoute);
		await dispatch(actionTypes.reconstituteRoute);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		
		await dispatch(actionTypes.fetchYears);
		await dispatch(actionTypes.updateListingItems);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		if(!state.filter.year)
			return;
		
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			let items = await hrController.getAdministrationEmployeesItems(String(state.filter.year),
				VacationPlanEmployeesMapper.mapToGetAdministrationEmployeesParameters(state));
			
			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => HrAdministrationEmployeesItemService.map(x)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeService.resolveRouteQuery(rootState.route.query);
		let routeParams = await routeService.resolveRouteParams(rootState.route.params);
		
		if(state.filter.year !== routeParams.year)
			commit(mutationTypes.SET_FILTER_YEAR, routeParams.year);
		commit(mutationTypes.SET_FILTER_DEPARTMENT_IDS, routeQuery.departmentIds);
		commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, routeQuery.employeeIds);
		commit(mutationTypes.SET_SORTING_TYPE, routeQuery.sort);
		commit(mutationTypes.SET_SORTING_ORDER, routeQuery.sortDirection);
		
		// Если при открытии страницы уже стоят фильтры, нужно сразу загрузить соответствующие справочники
		if(routeQuery.departmentIds.length)
			await dispatch(actionTypes.fetchDepartments);
		if(routeQuery.employeeIds.length)
			await dispatch(actionTypes.fetchEmployees);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.VACATION_PLANS_ADMINISTRATION_EMPLOYEES,
			params: routeService.resolveRouteParamsDictionary(state),
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.VACATION_PLANS_ADMINISTRATION_EMPLOYEES,
			params: routeService.resolveRouteParamsDictionary(state),
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchDepartments]({ commit, state, dispatch }) {
		if(state.departments.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, true);
		
		try {
			const items = await hrController.getDepartments();
			
			commit(mutationTypes.SET_DEPARTMENTS, items);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, false);
		}
	},
	async [actionTypes.fetchEmployees]({ commit, state, dispatch }) {
		if(state.employees.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, true);
		
		try {
			const { employees } = await hrController.getEmployees({} as ApiHrGetEmployeesParameters);
			
			commit(mutationTypes.SET_EMPLOYEES, employees.map(x => ({ ...x, fullName: formatFullName(x) })));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, false);
		}
	},
	async [actionTypes.fetchYears]({ commit, state, dispatch }) {
		if(state.years.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_YEARS_LOADING, true);
		
		try {
			const items = await hrController.getAdministrationYears();
			
			commit(mutationTypes.SET_YEARS, items);
			
			if(!router.currentRoute.params.year || !items.some(x => x.value === +router.currentRoute.params.year))
				commit(mutationTypes.SET_FILTER_YEAR, getHrDefaultYear(items)?.value);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_YEARS_LOADING, false);
		}
	},
	async [actionTypes.importEmployees]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			const response = await hrController.importVacationPlanEmployees(String(state.filter.year),
				ApiHrImportEmployeesRequestMapper.map(state.importEmployeesRequest));
			
			if(response.importedCount === 0) {
				alertService.addError(AlertKeys.EMPLOYEES_NOT_IMPORTED);
			} else {
				commit(mutationTypes.SET_IMPORT_EMPLOYEES_RESPONSE, HrImportEmployeesResponseService.map(response));
				
				await dispatch(actionTypes.updateListingItems);
			}
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
			
			throw error;
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.importEmployee]({ commit, state, dispatch }, employeeId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			const response = await hrController.importVacationPlanEmployee(String(state.filter.year), employeeId);
			
			if(response.importedCount === 0) {
				alertService.addError(AlertKeys.EMPLOYEE_NOT_IMPORTED);
			} else {
				alertService.addInfo(AlertKeys.EMPLOYEE_SUUCCESS_IMPORTED);
				
				await dispatch(actionTypes.updateListingItems);
			}
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteEmployee]({ commit, state, dispatch }, employeeId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			await hrController.deleteVacationPlanEmployee(String(state.filter.year), employeeId);
			
			alertService.addInfo(AlertKeys.EMPLOYEE_SUUCCESS_DELETED);
			
			await dispatch(actionTypes.updateListingItems);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateEmployeeVacationDays]({ commit, state, dispatch }, employeeId) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			await hrController.updateEmployeeVacationDays(String(state.filter.year),
				employeeId,
				ApiHrUpdateEmployeeVacationDaysMapper.map(state.updateVacationDaysRequest));
			
			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
			
			await dispatch(actionTypes.updateListingItems);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateCanCreateUnplannedVacationApplication]({ commit, state, dispatch },
		{ employeeId, value }: { employeeId: string, value: boolean })
	{
		commit(mutationTypes.ADD_SAVING_CAN_CREATE_UNPLANNED_VACATION_APPLICATION_EMPLOYEE_ID, employeeId);
		
		try {
			await hrController.updateEmployeeCanCreateUnplannedVacationApplication(String(state.filter.year), employeeId, value);
			
			commit(mutationTypes.SET_EMPLOYEE_CAN_CREATE_UNPLANNED_VACATION_APPLICATION, { employeeId, value });
			
			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.REMOVE_SAVING_CAN_CREATE_UNPLANNED_VACATION_APPLICATION_EMPLOYEE_ID, employeeId);
		}
	}
};

const mutations = <MutationTree<VacationPlanEmployeesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_FILTER_YEAR](state, value) {
		state.filter.year = value;
	},
	[mutationTypes.SET_FILTER_DEPARTMENT_IDS](state, value) {
		state.filter.departmentIds = value;
	},
	[mutationTypes.SET_FILTER_EMPLOYEE_IDS](state, value) {
		state.filter.employeeIds = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new VacationPlanEmployeesFilter(first(state.years)?.value);
	},
	[mutationTypes.SET_IS_YEARS_LOADING](state, value) {
		state.isYearsLoading = value;
	},
	[mutationTypes.SET_IS_DEPARTMENTS_LOADING](state, value) {
		state.isDepartmentsLoading = value;
	},
	[mutationTypes.SET_IS_EMPLOYEES_LOADING](state, value) {
		state.isEmployeesLoading = value;
	},
	[mutationTypes.SET_YEARS](state, value) {
		state.years = value;
	},
	[mutationTypes.SET_DEPARTMENTS](state, value) {
		state.departments = value;
	},
	[mutationTypes.SET_EMPLOYEES](state, value) {
		state.employees = value;
	},
	[mutationTypes.SET_IMPORT_EMPLOYEES_RESPONSE](state, value) {
		state.importEmployeesResponse = value;
	},
	[mutationTypes.RESET_IMPORT_EMPLOYEES_REQUEST](state, value) {
		state.importEmployeesRequest = HrImportEmployeesRequestService.getEmpty();
	},
	[mutationTypes.RESET_IMPORT_EMPLOYEES_RESPONSE](state, value) {
		state.importEmployeesResponse = HrImportEmployeesResponseService.getEmpty();
	},
	[mutationTypes.RESET_UPDATE_VACATION_DAYS_REQUEST](state) {
		state.updateVacationDaysRequest = HrUpdateEmployeeVacationDaysRequestService.getEmpty();
	},
	[mutationTypes.SET_UPDATE_VACATION_DAYS_REQUEST_VACATION_DAYS](state, value) {
		state.updateVacationDaysRequest.vacationDays = value;
	},
	[mutationTypes.SET_UPDATE_VACATION_DAYS_REQUEST_COMMENT](state, value) {
		state.updateVacationDaysRequest.comment = value;
	},
	[mutationTypes.SET_IMPORT_EMPLOYEES_REQUEST_HIRE_DATE_FROM](state, value) {
		state.importEmployeesRequest.hireDateFrom = value;
	},
	[mutationTypes.SET_IMPORT_EMPLOYEES_REQUEST_HIRE_DATE_TO](state, value) {
		state.importEmployeesRequest.hireDateTo = value;
	},
	[mutationTypes.SET_EMPLOYEE_CAN_CREATE_UNPLANNED_VACATION_APPLICATION](state, { employeeId, value }) {
		state.listing.items.find(x => x.id === employeeId)!.canCreateUnplannedVacationApplication = value;
	},
	[mutationTypes.ADD_SAVING_CAN_CREATE_UNPLANNED_VACATION_APPLICATION_EMPLOYEE_ID](state, value) {
		state.savingCanCreateUnplannedVacationApplicationEmployeeIds.push(value);
	},
	[mutationTypes.REMOVE_SAVING_CAN_CREATE_UNPLANNED_VACATION_APPLICATION_EMPLOYEE_ID](state, value) {
		state.savingCanCreateUnplannedVacationApplicationEmployeeIds =
			state.savingCanCreateUnplannedVacationApplicationEmployeeIds.filter(x => x !== value);
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const vacationPlanEmployeesModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default vacationPlanEmployeesModule;
