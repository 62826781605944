<template>
	<div>
		<frp-dialog :value="value"
					persistent
					:title="$t('dialogs.plannedVacationApplication.title')"
					:maxWidth="496"
					@update:value="handleClose">
			<template #content="{ onIntersect }">
				<v-form v-mutate="onIntersect" ref="form" v-model="formValid" :readonly="isFormSaving">
					<frp-form-item>
						<frp-text-field class="kpi-field"
										:value="employee"
										:loading="isVacationApplicationLoading"
										disabled
										:label="$t('fields.employee.label')">
						</frp-text-field>
					</frp-form-item>
					
					<frp-form-item>
						<frp-text-field class="kpi-field"
										:value="period"
										:loading="isVacationApplicationLoading"
										disabled
										:label="$t('fields.period.label')">
						</frp-text-field>
					</frp-form-item>
					
					<frp-form-item>
						<bod-autocomplete v-model="substituteId"
										  :items="formattedSubstitutes"
										  :loading="isVacationApplicationLoading || isSubstitutesLoading"
										  item-text="fullName"
										  item-value="id"
										  :disabled="!selectedTask.isToDo || !selectedTask.isCurrentUserAssignee"
										  color="blue"
										  :required="selectedTask.isToDo && selectedTask.isCurrentUserAssignee"
										  :label="$t('fields.substituteEmployee.label')"
										  :placeholder="$t('fields.substituteEmployee.placeholder')">
						</bod-autocomplete>
					</frp-form-item>
				</v-form>
			</template>
			
			<template #footer>
				<v-spacer></v-spacer>
				
				<frp-btn elevation="0"
						 outlined
						 color="primary"
						 @click="handleClose">
					{{ $t("buttons.close") }}
				</frp-btn>
				<frp-btn v-if="selectedTask.isCurrentUserAssignee && selectedTask.isToDo"
						 elevation="0"
						 color="blue"
						 dark
						 :loading="isSubstituteSaving"
						 @click="handleSave">
					{{ $t("buttons.save") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</div>
</template>

<script>
import { ApiHrAbsenceTypeEnum } from "@/api/hr/types/absence/ApiHrAbsenceTypeEnum";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDocumentDialog from "@/components/dialogs/FrpDocumentDialog.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpTreeAutocomplete from "@/components/fields/FrpTreeAutocomplete.vue";
import HrRangeDateField from "@/components/fields/HrRangeDateField.vue";
import FrpFormItem from "@/components/markup/FrpFormItem.vue";
import formMixin from "@/mixins/formMixin";
import { dateFormat } from "@/utils/formats";
import { formatFullName } from "@/utils/formatting";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import { RouteNames } from "Router/hr/routes";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/hr/modules/tasks/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import { formatDate } from "@/utils/dates";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, formMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace,
			formatFullName,
			dateFormat,
			ApiHrAbsenceTypeEnum,
			absenceTypes: Object.values(ApiHrAbsenceTypeEnum).filter(x => {
				return [ApiHrAbsenceTypeEnum.Study, ApiHrAbsenceTypeEnum.TraineeJob, ApiHrAbsenceTypeEnum.Other].includes(x);
			}).map(x => ({ text: this.$t(`aliases.absenceType.${x}`), value: x }))
		};
	},
	computed: {
		...mapState({
			selectedTask: state => state.selectedTask,
			isSubstituteSaving: state => state.isSubstituteSaving,
			isSubstitutesLoading: state => state.isSubstitutesLoading,
			isVacationApplicationLoading: state => state.isVacationApplicationLoading,
			substitutes: state => state.substitutes,
			newSubstituteRequest: state => state.newSubstituteRequest,
			vacationApplication: state => state.vacationApplication,
		}),
		formattedSubstitutes() {
			return this.substitutes.map(x => ({ ...x, fullName: formatFullName(x) }));
		},
		employee() {
			if(!this.vacationApplication || this.isVacationApplicationLoading)
				return;
			
			return formatFullName(this.vacationApplication.employee);
		},
		period() {
			if(!this.vacationApplication || this.isVacationApplicationLoading)
				return;
			
			const start = formatDate(this.vacationApplication.startDate, dateFormat);
			const end = formatDate(this.vacationApplication.endDate, dateFormat);
			
			return `${start} - ${end}`;
		},
		substituteId: {
			get() {
				if(this.isVacationApplicationLoading || this.isSubstitutesLoading)
					return;
				
				if(!this.selectedTask.isToDo)
					return this.vacationApplication?.vacationSubstitute?.id;
				
				return this.newSubstituteRequest.vacationSubstituteEmployeeId;
			},
			set(value) {
				this.setNewSubstituteRequestSubstituteId(value);
			}
		}
	},
	methods: {
		formatDate,
		...mapMutations({
			setFilterTaskId: mutationTypes.SET_FILTER_TASK_ID,
			resetSelectedTask: mutationTypes.RESET_SELECTED_TASK,
			resetNewSubstituteRequest: mutationTypes.RESET_NEW_SUBSTITUTE_REQUEST,
			setNewSubstituteRequestSubstituteId: mutationTypes.SET_NEW_SUBSTITUTE_REQUEST_SUBSTITUTE_ID
		}),
		...mapActions({
			trySaveSubstitute: actionTypes.trySaveSubstitute,
			fetchSubstitutes: actionTypes.fetchSubstitutes,
			fetchVacationApplication: actionTypes.fetchVacationApplication,
		}),
		async handleSave() {
			const res = await this.trySaveSubstitute();
			
			if(res)
				this.handleClose();
		},
		handleClose() {
			this.setFilterTaskId("");
			this.resetSelectedTask();
			this.$emit("update:value", false);
		}
	},
	watch: {
		value: {
			async handler(value) {
				if(!value)
					return;
				
				this.resetNewSubstituteRequest();
				
				await this.fetchVacationApplication();
				
				await this.fetchSubstitutes();
				
				await this.$nextTick();
				
				this.$refs.form.resetValidation();
			},
			immediate: true
		}
	},
	components: {
		FrpDocumentDialog,
		FrpTextField,
		FrpTextarea,
		HrRangeDateField, FrpFormItem,
		FrpTreeAutocomplete,
		FrpDialog,
		BodAutocomplete,
		FrpBtn
	}
};
</script>
