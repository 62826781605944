<template>
	<frp-dialog :value="value"
				max-width="525"
				@update:value="$emit('update:value', $event)">
		<template #prepend v-if="value && employee">
			<v-row class="align-center flex-wrap pt-6 pb-2 pr-3 pl-2 primary--text text--darken-1">
				<v-col class="d-flex px-0" style="align-self: baseline">
					<frp-avatar :id="employee.pictureFileId" size="85" class="mr-5"></frp-avatar>
					
					<div class="d-flex flex-column justify-start">
						<span style="font-size: 20px; font-weight: 600" class="mb-1">
							{{ formatFullName(employee) }}
						</span>
						<span style="font-size: 16px; line-height: 22px">
							{{ employee.department.name }}
						</span>
						<span style="font-size: 16px; line-height: 22px">
							{{ employee.position }}
						</span>
					</div>
				</v-col>
			</v-row>
		</template>

		<template #subtitle="{ onIntersect }" v-if="value">
			<div class="font-weight-bold mt-n2">
				<template v-if="task.vacationApplication">
					<frp-router-link-btn v-if="task.vacationApplication.id"
										 :to="{ name: RouteNames.VACATION_APPLICATION, params: { id: task.vacationApplication.id } }"
										 blank underline>
						<span style="font-size: 16px" class="font-weight-bold">
							{{ $t(`aliases.absencesVacationType.${task.vacationApplication.type}`) }}
						</span>
					</frp-router-link-btn>
					
					<span v-else>{{ $t(`aliases.absencesVacationType.${task.vacationApplication.type}`) }}</span>
				</template>
				
				<span v-else>{{ $t(`aliases.absenceType.${task.type}`) }}</span>
			</div>
		</template>

		<template #content="{ onIntersect }" v-if="value">
			<frp-text-field class="kpi-field mb-2"
							hide-details
							:value="`${format(task.startDate, dateFormat)} - ${format(task.endDate, dateFormat)}`"
							disabled
							:label="$t('fields.period.label')">
			</frp-text-field>
			
			<frp-textarea v-if="task.comment"
						  :value="task.comment"
						  :label="$t('fields.hrVacationDaysComment.label')"
						  disabled
						  auto-grow>
			</frp-textarea>
		</template>

		<template #footer v-if="value">
			<frp-btn outlined
					 @click="handleClose"
					 color="primary">
				{{ $t("buttons.close") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { ApiHrVacationTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationTypeEnum";
import FrpRouterLinkBtn from "@/components/buttons/FrpRouterLinkBtn.vue";
import FrpAvatar from "@/components/common/FrpAvatar.vue";
import HrVacationPlanStateLabel from "@/components/common/HrVacationPlanStateLabel.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import { dateFormat } from "@/utils/formats";
import { Permissions } from "@/constants/permissions";
import { formatFullName } from "@/utils/formatting";
import { format } from "date-fns";
import { RouteNames } from "Router/hr/routes";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/hr/modules/absences/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextField from "Components/fields/FrpTextField";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace,
			Permissions,
			format,
			dateFormat,
			HrVacationPlanStateEnum,
			ApiHrVacationTypeEnum,
			isFormValid: false,
			formatFullName
		};
	},
	computed: {
		...mapState({
			formattedItems: state => state.formattedItems,
			currentOpenedAbsenceId: state => state.currentOpenedAbsenceId,
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
		}),
		task() {
			return this.formattedItems.map(x => x.periods).flat().find(x => x.id === this.currentOpenedAbsenceId);
		},
		employee() {
			return this.task.employee;
		},
	},
	methods: {
		handleClose() {
			this.$emit("update:value", false);
		},
	},
	components: {
		FrpAvatar,
		HrVacationPlanStateLabel,
		FrpRouterLinkBtn,
		FrpDateField,
		FrpTextarea,
		FrpDialog,
		FrpBtn,
		FrpTextField
	}
};
</script>
