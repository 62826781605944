import { ApiHrGetTasksParameters } from "@/api/hr/types/tasks/apiHrGetTasksParameters";
import TasksState from "@/store/hr/modules/tasks/types/tasksState";
import { ApiHrTasksStateEnum } from "@/api/hr/types/tasks/apiHrTasksStateEnum";

export default class TasksMapper {
	static mapToGetTasksParameters(source: TasksState): ApiHrGetTasksParameters {
		
		return {
			taskTypes: source.filter.taskTypes,
			taskStates: [...source.filter.taskStates].filter(x => x !== ApiHrTasksStateEnum.All),
			taskAssigneeIds: source.filter.taskAssigneeIds
		};
	}
}
