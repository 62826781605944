<template>
	<div class="d-flex flex-column">
		<div v-if="messengerMode === MessengerModeType.ANSWER" class="pb-0 mt-5 d-flex">
			<div class="bar-messenger-answer-field d-flex align-center"
				 :class="{'bar-messenger-answer-field--full-width': isSmallDisplay}">
				<frp-icon class="mx-2"
						  :color="colors.blue.base"
						  src="ico_curve-arrow-left">
				</frp-icon>
				
				<div style="width: 100%;" class="py-3">
					<v-subheader class="pa-0 mb-1 d-flex justify-space-between"
								 style="height: fit-content; width: 100%">
						<div class="d-flex flex-column text-body-2 primary--text">
							<span class="font-weight-bold">
								{{ getRelatedAnswerMessageEmployeeFullName || "" }}
							</span>
						</div>
						<frp-btn small
								 height="30"
								 style="margin-bottom: -15px !important; margin-top: -15px !important;"
								 :color="colors.primary.base"
								 icon
								 @click="handleCancelSelectRelatedMessage">
							<frp-icon src="ico_close" :color="colors.primary.base"></frp-icon>
						</frp-btn>
					</v-subheader>
					
					<span class="text-body-2 primary--text">
						{{ getRelatedAnswerMessageText || "" }}
					</span>
				</div>
			</div>
		</div>
		<div :class="{'pt-0': messengerMode === MessengerModeType.ANSWER}">
			<v-form :ref="refs.form"
					v-model="formValid"
					class="d-flex align-center justify-end"
					style="margin-top: 6px !important; gap: 16px"
					:class="{'flex-column': isSmallDisplay}"
					:style="isSmallDisplay ? 'gap: 8px' : ''">
				<frp-autocomplete v-model="selectedStaffEmployee"
								  :items="frpEmployees"
								  :top="menuTop"
								  :nudge-top="menuTop ? 5 : 0"
								  item-text="fullName"
								  item-value="id"
								  :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.SEND_MESSAGE_BUTTON) || messengerMode === MessengerModeType.ANSWER || isMessengerFormDisabled"
								  color="blue"
								  :class="{'bar-messenger-autocomplete--full-width': isSmallDisplay}"
								  class="bar-messenger-autocomplete"
								  hide-details
								  :placeholder="$t('fields.responseMessage.placeholder')">
				</frp-autocomplete>
				<frp-text-field v-model="messageText"
								hide-details
								required
								:disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.SEND_MESSAGE_BUTTON) || isMessengerFormDisabled"
								:rules="validation.messageText"
								style="width: 100%"
								@keydown.enter="handleSendMessage"
								:placeholder="$t('fields.messageText.placeholder')">
				</frp-text-field>
				<frp-btn elevation="0"
						 dark
						 :style="isSmallDisplay ? 'width: 100%' : ''"
						 :loading="isFormSaving"
						 :color="colors.blue.base"
						 @click="handleSendMessage"
						 :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.SEND_MESSAGE_BUTTON) || !formValid || isMessagesFetching || isMessengerFormDisabled">
					{{ $t("buttons.send") }}
				</frp-btn>
			</v-form>
		</div>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { namespace } from "@/store/bar/modules/bankAccountApplication/modules/messenger";
import { mutationTypes, actionTypes } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import { MessengerModeType } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/messengerModeType";
import { Permissions } from "@/constants/permissions";
import { getterTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { BankAccountApplicationButtonTypeEnum } from "@/store/bar/types/BankAccountApplicationButtonTypeEnum";
import storeManager from "@/store/manager";
import { maxLengths, prepareMaxLengthRule } from "@/utils/validation";
import { createNamespacedHelpers } from "vuex";
import { formatDate } from "Utils/dates";
import { timeFormat } from "Utils/formats";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const bankAccountApplicationHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplication.namespace);

export default {
	mixins: [colorsMixin, formMixin, authorizationMixin],
	props: {
		menuTop: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			formatDate,
			timeFormat,
			namespace,
			MessengerModeType,
			maxLengthsAnswerMessageText: 150,
			validation: {
				messageText: [prepareMaxLengthRule({ maxLength: maxLengths.upperMiddle })]
			},
			messageText: ""
		};
	},
	computed: {
		BankAccountApplicationButtonTypeEnum() {
			return BankAccountApplicationButtonTypeEnum;
		},
		...mapState({
			state: state => state,
			selectedStaffEmployeeId: state => state.selectedStaffEmployeeId,
			messages: state => state.messages,
			editableMessageItem: state => state.editableMessageItem,
			isMessengerFullscreen: state => state.isMessengerFullscreen,
			isMessagesFetching: state => state.isMessagesFetching,
			isMessageDeleting: state => state.isMessageDeleting,
			messengerMode: state => state.messengerMode
		}),
		...bankAccountApplicationHelpers.mapState({
			staffEmployees: state => state.staffEmployees,
			frpEmployees: state => state.frpEmployees
		}),
		...bankAccountApplicationHelpers.mapGetters({
			buttonStatus: getterTypes.buttonUnlocked
		}),
		isMessengerFormDisabled() {
			return this.isMessageDeleting || this.isFormSaving || !this.can([Permissions.BAR_APPLICATION_MESSAGES_CREATE, Permissions.BAR_APPLICATION_MESSAGES_UPDATE]);
		},
		selectedStaffEmployee: {
			get() {
				if(!this.editableMessageItem.toEmployees.length) return "";
				
				return this.editableMessageItem.toEmployees[0];
			},
			set(value) {
				!value ? this.resetEditableMessageItemToEmployees() : this.setSelectedStaffEmployee(value);
			}
		},
		getRelatedAnswerMessageEmployeeFullName() {
			return this.staffEmployees.find(x => x.id === this.editableMessageItem.toEmployees[0])?.fullName || "";
		},
		getRelatedAnswerMessageText() {
			const messageText = this.messages.find(x => x.id === this.editableMessageItem.relatedMessageId)?.messageText || "";
			
			return messageText.length > this.maxLengthsAnswerMessageText ? `${messageText.slice(0, this.maxLengthsAnswerMessageText)}...` : messageText;
		},
		isSmallDisplay() {
			return this.$vuetify.breakpoint.smAndDown || (this.$vuetify.breakpoint.mdAndDown && !this.isMessengerFullscreen);
		}
	},
	methods: {
		...mapMutations({
			setMessengerMode: mutationTypes.SET_MESSENGER_MODE,
			resetEditableMessageItemToEmployees: mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_TO_EMPLOYEES,
			resetEditableMessageItemRelatedMessageId: mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_RELATED_MESSAGE_ID,
			removeEditableMessageItemSelectedEmployeeId: mutationTypes.REMOVE_EDITABLE_MESSAGE_ITEM_SELECTED_EMPLOYEE_ID,
			setEditableMessageItemSelectedEmployeeId: mutationTypes.SET_EDITABLE_MESSAGE_ITEM_SELECTED_EMPLOYEE_ID,
			resetEditableMessageItemMessageText: mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_MESSAGE_TEXT,
			setEditableMessageItemMessageText: mutationTypes.SET_EDITABLE_MESSAGE_ITEM_MESSAGE_TEXT,
			resetEditableMessageItemMessageType: mutationTypes.RESET_EDITABLE_MESSAGE_ITEM_MESSAGE_TYPE
		}),
		...mapActions({
			sendMessage: actionTypes.sendMessage
		}),
		setSelectedStaffEmployee(value) {
			if(!value)
				this.handleCancelSelectRelatedMessage();
			
			this.resetEditableMessageItemToEmployees();
			this.setEditableMessageItemSelectedEmployeeId(value);
		},
		async handleSendMessage() {
			this.setEditableMessageItemMessageText(this.messageText);
			await this.sendMessage();
			this.messageText = "";
			this.resetEditableMessageItemMessageText();
			this.resetEditableMessageItemMessageType();
			this.handleCancelSelectRelatedMessage();
			this.$refs[this.refs.form].resetValidation();
			this.$emit("update:scroll-to-bottom");
			await this.$emit("message:sent");
		},
		handleCancelSelectRelatedMessage() {
			this.resetEditableMessageItemToEmployees();
			this.resetEditableMessageItemRelatedMessageId();
			this.setMessengerMode(MessengerModeType.DEFAULT);
		}
	},
	components: { FrpTextField, FrpAutocomplete, FrpIcon, FrpBtn }
};
</script>

<style lang="scss">
.bar-messenger-autocomplete {
	.v-input {
		width: 230px !important;
	}

	&--full-width {
		width: 100%;

		.v-input {
			width: 100% !important;
		}
	}
}

.bar-messenger-answer-field {
	width: calc(100% - 138px);
	min-height: 66px;
	background: var(--v-white-lighten1);
	border: 1px solid var(--v-grey-base);
	border-radius: 6px;

	&--full-width {
		width: calc(100% - 60px);
	}
}
</style>
