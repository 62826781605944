<template>
	<v-app>
		<v-app-bar height="52"
				   elevation="0"
				   class="flex-grow-0 hr-app-bar"
				   dark
				   color="primary lighten-1">
			<v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="drawer = !drawer"></v-app-bar-nav-icon>
			
			<v-list class="pa-0 hr-menu" v-if="$vuetify.breakpoint.mdAndUp">
				<v-list-item class="px-8" v-for="(item, i) in menuItems"
							 :class="{ 'hr-menu-active-item': item.childrenRoutes && item.childrenRoutes.includes($route.name) }"
							 :value="i"
							 :disabled="item.disabled"
							 :key="i"
							 :to="item.routeConfig"
							 :href="item.href">
					<v-list-item-title v-text="item.text"></v-list-item-title>
				</v-list-item>
			</v-list>
			
			<v-spacer></v-spacer>
			
			<v-menu bottom
					nudge-bottom="20"
					max-width="250"
					offset-y
					v-if="isInitialized && $vuetify.breakpoint.mdAndUp"
					content-class="elevation-5 menu-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-btn max-width="300"
						   class="text-none profile-btn text-left justify-end user-menu pr-2 pl-5 text-subtitle-2 white--text"
						   style="border: none"
						   v-ripple="false"
						   v-bind="attrs"
						   v-on="on" text outlined>
						<span class="text-truncate">
							{{ `${user.firstName} ${user.lastName}` }}
						</span>
						<frp-icon class="ml-2" src="ico_bod-chevron-down" :color="colors.grey.lighten4"></frp-icon>
						<frp-icon class="ml-2" src="ico_help" :color="colors.warning.base"></frp-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="logout">
						<v-icon :color="colors.primary.lighten1">mdi-logout</v-icon>
						<v-list-item-title class="ml-2">{{ $t("buttons.logout") }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		
		<div v-if="pageMode === PageModeType.OK" class="white darken-2 fill-height d-flex">
			<router-view :key="$route.meta.key || $route.name"/>
		</div>
		<frp-page-not-found v-else-if="pageMode === PageModeType.PAGE_NOT_FOUND"></frp-page-not-found>
		<frp-access-denied-error v-else-if="pageMode === PageModeType.ACCESS_DENIED"></frp-access-denied-error>
		<frp-alerts left-offset="0"></frp-alerts>
	</v-app>
</template>

<script>
import { externalUrls } from "@/constants/hr/externalUrls";
import FrpAlerts from "Components/alerts/FrpAlerts";
import { findAllByAncestor } from "Router/hr/routes";
import { PageModeType } from "Store/hr/types/pageModeType";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import FrpAccessDeniedError from "Views/errors/FrpAccessDeniedError";
import storeManager from "Store/manager";
import rootTypes from "Store/hr/types";
import { mapState, mapMutations } from "vuex";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpIcon from "Components/icon/FrpIcon";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { RouteNames } from "Router/hr/routes";
import SecurityService from "Services/auth/securityService";
import { actionTypes, namespace } from "Store/hr/modules/user/types";
import { createNamespacedHelpers } from "vuex";

const securityService = new SecurityService();
const { mapState: mapUserState, mapActions: mapUserActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	data() {
		return {
			storeManager,
			PageModeType,
			drawer: false
		};
	},
	computed: {
		...mapState({
			pageMode: state => state.pageMode
		}),
		...mapUserState({
			isInitialized: state => state.isInitialized,
			user: state => state.user
		}),
		menuItems() {
			return [
				{
					text: this.$t("navigation.hr.own"),
					permissions: [],
					routeConfig: { name: RouteNames.EMPLOYEE, params: { id: this.user.id } },
					childrenRoutes: findAllByAncestor(RouteNames.EMPLOYEE).map(x => x.model.name),
					disabled: !this.user.id
				},
				{
					text: this.$t("navigation.hr.employees"),
					permissions: [],
					routeConfig: { name: RouteNames.EMPLOYEES },
					childrenRoutes: findAllByAncestor(RouteNames.EMPLOYEES).map(x => x.model.name)
				},
				{
					text: this.$t("navigation.hr.tasks"),
					permissions: [],
					routeConfig: { name: RouteNames.TASKS },
					childrenRoutes: findAllByAncestor(RouteNames.TASKS).map(x => x.model.name)
				},
				{
					text: this.$t("navigation.hr.vacationApplications"),
					permissions: [],
					routeConfig: { name: RouteNames.VACATION_APPLICATIONS },
					childrenRoutes: findAllByAncestor(RouteNames.VACATION_APPLICATIONS).map(x => x.model.name)
				},
				{
					text: this.$t("navigation.hr.vacationPlans"),
					permissions: [],
					routeConfig: { name: RouteNames.VACATION_PLANS },
					childrenRoutes: findAllByAncestor(RouteNames.VACATION_PLANS).map(x => x.model.name)
				},
				{
					text: this.$t("navigation.hr.absences"),
					permissions: [],
					routeConfig: { name: RouteNames.ABSENCES },
					childrenRoutes: findAllByAncestor(RouteNames.ABSENCES).map(x => x.model.name)
				},
				{
					text: this.$t("navigation.hr.KPI"),
					permissions: [],
					href: this.kpiUrl
				},
				{
					text: this.$t("navigation.hr.support"),
					permissions: [],
					routeConfig: { name: RouteNames.SUPPORT }
				},
				{
					text: this.$t("navigation.hr.otherModules"),
					permissions: [],
					href: this.otherModulesUrl
				}
			];
		},
		kpiUrl() {
			return `${externalUrls.kpiRegistry}/${new Date().getFullYear()}`;
		},
		otherModulesUrl() {
			return externalUrls.usefulLinks.informationSystems;
		}
	},
	methods: {
		...mapMutations({
			resetPageMode: rootTypes.mutationTypes.RESET_PAGE_MODE
		}),
		...mapUserActions({
			initializeStore: actionTypes.initialize
		}),
		logout() {
			this.signOut();
		}
	},
	async created() {
		if(!this.isInitialized)
			await this.initializeStore();
	},
	watch: {
		"$route"(to, from) {
			if(!to || !from)
				return;
			
			if(to.name !== from.name)
				this.resetPageMode();
		}
	},
	components: {
		FrpAlerts,
		FrpPageNotFound,
		FrpAccessDeniedError,
		FrpBtn,
		FrpIcon
	}
};
</script>

<style lang="scss">
.hr-app-bar {
  z-index: 100;
  
  .v-toolbar__content {
	padding: 0 16px 0 0;
  }
}

.hr-menu {
  display: flex;
  background: none;
  height: 100%;

  a {
	padding: 0 20px !important;
	background-color: var(--v-primary-lighten1);
  }

  a::before {
	opacity: 0 !important;
  }

  .v-list-item--active,
  .hr-menu-active-item {
	color: var(--v-white-base) !important;
	background-color: var(--v-blue-base);
  }
}
</style>
