import { MonthEnum } from "@/constants/MonthEnum";

// В блоке KPI на странице HR-профиля отображаются данные за предыдущий квартал (HR_KPI_AMOUNT_OF_SUB_QUARTERS),
// если на текущий момент прошло менее 10 дней (HR_KPI_QUARTER_START_DAY) от начала квартала
export const HR_KPI_QUARTER_START_DAY = 10;
export const HR_KPI_AMOUNT_OF_SUB_QUARTERS = 1;

// Минимальный месяц для начала подстановки по умолчанию следующего года на странице ПГ и администрирования ПГ
export const HR_FILTER_NEXT_YEAR_START_MONTH = MonthEnum.NOVEMBER;

// Минимальное и максимальное количество дней от текущей даты до начала планового отпуска
// для показа уведомления о необходимости подать заявление
export const HR_VACATION_APPLICATION_REQUIRES_ALERT_MAX_DAYS = 21;
export const HR_VACATION_APPLICATION_REQUIRES_ALERT_MIN_DAYS = 7;

// Количество дней до минимально возможной даты для переноса отпусков в ПГ
export const HR_VACATION_PLANS_VACATION_REPLACEMENT_MIN_INTERVAL_FROM_TODAY = 10;

// Количество дней до минимально возможной даты для подачи заявления на учебный отпуск или отпуск по заявлению
export const HR_VACATION_APPLICATION_STUDY_UNPLANNED_VACATION_MIN_INTERVAL_FROM_TODAY = 10;