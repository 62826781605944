import { render, staticRenderFns } from "./BarBankAccountApplicationMessengerActions.vue?vue&type=template&id=8980d5ee"
import script from "./BarBankAccountApplicationMessengerActions.vue?vue&type=script&lang=js"
export * from "./BarBankAccountApplicationMessengerActions.vue?vue&type=script&lang=js"
import style0 from "./BarBankAccountApplicationMessengerActions.vue?vue&type=style&index=0&id=8980d5ee&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports