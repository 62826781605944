import { ApiHrRoleEnum } from "@/api/hr/types/ApiHrRoleEnum";
import { Permissions } from "@/constants/permissions";
import { uniq } from "lodash";

export const getHrPermissions = (roles: ApiHrRoleEnum[]): Permissions[] => {
    const administratorPermissions = [
		Permissions.HR_VACATIONS_PLAN_UPDATE,
		Permissions.HR_VACATIONS_PLAN_UPDATE_APPROVE,
		Permissions.HR_VACATIONS_GANTT_READ,
		Permissions.HR_VACATIONS_PLAN_APPROVE,
		Permissions.HR_VACATIONS_PLAN_DECLINE,
		Permissions.OWN_HR_VACATIONS_PLAN_APPROVE,
		Permissions.OWN_HR_VACATIONS_PLAN_DECLINE
	];
    const headPermissions = [
		Permissions.HR_VACATIONS_GANTT_READ,
		Permissions.HR_VACATIONS_PLAN_APPROVE,
		Permissions.HR_VACATIONS_PLAN_DECLINE
	];
    const employeePermissions = [
		Permissions.HR_VACATIONS_GANTT_READ,
	];
	
	const res = [];

	if(roles.includes(ApiHrRoleEnum.Administrator))
        res.push(...administratorPermissions);
	if(roles.includes(ApiHrRoleEnum.Head))
        res.push(...headPermissions);
	if(roles.includes(ApiHrRoleEnum.Employee))
        res.push(...employeePermissions);
	
	return uniq(res);
};
