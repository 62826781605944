import { render, staticRenderFns } from "./FrpRouterLinkBtn.vue?vue&type=template&id=3e6d5c2a&scoped=true"
import script from "./FrpRouterLinkBtn.vue?vue&type=script&lang=js"
export * from "./FrpRouterLinkBtn.vue?vue&type=script&lang=js"
import style0 from "./FrpRouterLinkBtn.vue?vue&type=style&index=0&id=3e6d5c2a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e6d5c2a",
  null
  
)

export default component.exports