<template>
	<frp-dialog :value="value"
				v-if="value"
				:title="$t('dialogs.hrVacationPlanApproval.title', { version: openedApprovalVacationPlanNumber })"
				max-width="626"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<span class="font-weight-bold primary--text mt-3" slot="font-size: 18px">{{ $t("dialogs.hrApplicationState.subtitle") }}</span>
			
			<v-divider class="mt-3"></v-divider>
			
			<hr-approval v-bind="{...approval, approvers: filteredApprovers}"
						 no-created-at
						 :deletable-approvers="isHrUserAdministrator"
						 @delete="removeApprover"
						 :is-deliting-disabled="isApproverRemoving" />
		</template>
		
		<template #footer>
			<frp-btn elevation="0" color="blue" dark
					 @click="handleClose">
				{{ $t("buttons.close") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";
import HrApproval from "@/components/common/HrApproval.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { RouteNames } from "@/router/hr/routes";
import { getterTypes as userGetterTypes, namespace as userNamespace } from "@/store/hr/modules/user/types";
import { namespace, actionTypes, getterTypes, mutationTypes } from "@/store/hr/modules/vacationPlans/types";
import { cloneDeep } from "lodash";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "@/mixins/colorsMixin";
import FrpBtn from "@/components/buttons/FrpBtn.vue";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const { mapState: mapUserState, mapGetters: mapUserGetters } = createNamespacedHelpers(userNamespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			approval: state => state.approval,
			isApproverRemoving: state => state.isApproverRemoving,
			openedApprovalVacationPlanNumber: state => state.openedApprovalVacationPlanNumber
		}),
		...mapGetters({
			filteredFormattedItems: getterTypes.filteredFormattedItems,
		}),
		...mapUserGetters({
			isHrUserAdministrator: userGetterTypes.isHrUserAdministrator
		}),
		filteredApprovers() {
			return this.approval.approvers.filter(a => a.state !== ApiHrVacationApplicationStateEnum.Withdrawn);
		}
	},
	methods: {
		...mapActions({
			removeApprover: actionTypes.removeApprover
		}),
		...mapMutations({
			setApproval: mutationTypes.SET_APPROVAL,
			setIsVacationPlanApprovalDialogOpened: mutationTypes.SET_IS_VACATION_PLAN_APPROVAL_DIALOG_OPENED,
			setOpenedApprovalVacationPlanNumber: mutationTypes.SET_OPENED_APPROVAL_VACATION_PLAN_NUMBER
		}),
		handleClose() {
			this.setOpenedApprovalVacationPlanNumber(undefined);
			this.setApproval(undefined);
			this.$emit("update:value", false);
		}
	},
	components: {
		HrApproval,
		FrpDialog,
		FrpBtn
	}
};
</script>
