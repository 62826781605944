import ListingModel from "@/store/shared/listing/models/listingModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import TasksFilter from "@/store/hr/modules/tasks/types/tasksFilter";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import FormState from "@/store/shared/form/models/formState";
import ChangeRequest from "@/store/hr/modules/tasks/types/changeRequest";
import { HrVacationApplicationApproval } from "@/types/hr/vacationApplication/hrVacationApplicationApproval";
import { HrTasksItem } from "@/types/hr/task/hrTasksItem";
import { ApiHrUpdateVacationApplicationSubstituteRequest } from "@/api/hr/types/apiHrUpdateVacationApplicationSubstituteRequest";
import { HrVacationApplication } from "@/types/hr/vacationApplication/hrVacationApplication";

export default class TasksState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public listing: ListingModel<HrTasksItem>,
		public filter: TasksFilter,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public selectedTask: HrTasksItem = {} as HrTasksItem,
		public isDeclining: boolean = false,
		public isApproving: boolean = false,
		public isSigning: boolean = false,
		public isMarkingTaskInformationAsDone: boolean = false,
		public isSubstituteSaving: boolean = false,
		public isSubstitutesLoading: boolean = false,
		public isVacationApplicationLoading: boolean = false,
		public vacationApplication: HrVacationApplication | undefined = undefined,
		public newSubstituteRequest: ApiHrUpdateVacationApplicationSubstituteRequest = {} as ApiHrUpdateVacationApplicationSubstituteRequest,
		public declineReason: string = "",
		public pdfUrl: string = "",
		public employees: ApiHrEmployeesItem[] = [],
		public substitutes: ApiHrEmployeesItem[] = [],
		public isEmployeesLoading: boolean = false,
		public isDownloading: boolean = false,
		public request: ChangeRequest = new ChangeRequest(),
		public isFileLoading: boolean = false,
		public loadingApprovalItemIds: string[] = [],
		public file: Blob | undefined = undefined,
		public currentApproval: HrVacationApplicationApproval | undefined = undefined,
	)
	{
	}
}
