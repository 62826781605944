import SubscribersManager from "@/store/manager/subscribersManager";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/hr/modules/vacationPlanCalendar/types";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/hr/routes";
import router from "@/router/hr";
import routeTypes from "@/store/shared/route/types";
import { first } from "lodash";
import { HrController } from "@/api/hr";
import VacationPlanCalendarRouteService from "@/store/hr/modules/vacationPlanCalendar/services/vacationPlanCalendarRouteService";
import VacationPlanCalendarRouteParams from "@/store/hr/modules/vacationPlanCalendar/types/vacationPlanCalendarRouteParams";
import VacationPlanCalendarState from "@/store/hr/modules/vacationPlanCalendar/types/vacationPlanCalendarState";
import VacationPlanCalendarFilter from "@/store/hr/modules/vacationPlanCalendar/types/vacationPlanCalendarFilter";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { CalendarController } from "@/api/calendar";
import { CalendarDateHelper } from "@/types/calendar/calendarDate";

const abortService = new AbortService();
const hrController = new HrController(abortService);
const calendarController = new CalendarController(abortService);

const defaultRouteParams = new VacationPlanCalendarRouteParams();

const routeService = new VacationPlanCalendarRouteService(defaultRouteParams);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<VacationPlanCalendarState>()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new VacationPlanCalendarState(
			new VacationPlanCalendarFilter(),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<VacationPlanCalendarState>;

const getters = <GetterTree<VacationPlanCalendarState, any>>{
	[getterTypes.yearValues](state) {
		return state.years.map(x => x.value);
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<VacationPlanCalendarState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<VacationPlanCalendarState>(rootState, namespace);

	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !mutation.payload.to.query.year) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.reconstituteRoute));
			} else if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_YEAR):
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.fetchCalendar));
			});

			break;
		}
	}
};

const actions = <ActionTree<VacationPlanCalendarState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRoute);
		await dispatch(actionTypes.reconstituteRoute);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);

		await dispatch(actionTypes.fetchYears);
		await dispatch(actionTypes.fetchCalendar);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchCalendar]({ commit, state, dispatch }, isUpdate) {
		if(!state.filter.year)
			return;
		
		if(isUpdate)
			commit(mutationTypes.SET_IS_CALENDAR_UPDATING, true);
		else
			commit(mutationTypes.SET_IS_CALENDAR_LOADING, true);
		
		try {
			const { dates } = await calendarController.getWorkingDaysAndHolidays(state.filter.year);
			
			commit(mutationTypes.SET_CALENDAR_DATES, dates.map(x => CalendarDateHelper.map(x)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			if(isUpdate)
				commit(mutationTypes.SET_IS_CALENDAR_UPDATING, false);
			else
				commit(mutationTypes.SET_IS_CALENDAR_LOADING, false);
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeParams = await routeService.resolveRouteParams(rootState.route.params);

		if(state.filter.year !== routeParams.year)
			commit(mutationTypes.SET_FILTER_YEAR, routeParams.year);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.VACATION_PLANS_ADMINISTRATION_CALENDAR,
			params: routeService.resolveRouteParamsDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.VACATION_PLANS_ADMINISTRATION_CALENDAR,
			params: routeService.resolveRouteParamsDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchYears]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_YEARS_LOADING, true);

		try {
			const items = await hrController.getAdministrationYears();

			commit(mutationTypes.SET_YEARS, items);

			if(!router.currentRoute.params.year || !items.some(x => x.value === +router.currentRoute.params.year)) {
				const year = first(items);

				commit(mutationTypes.SET_FILTER_YEAR, year?.value);
			}
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_YEARS_LOADING, false);
		}
	},
	async [actionTypes.createYear]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_YEAR_CREATING, true);

		try {
			const { value } = await hrController.saveYear(String(state.filter.year ? state.filter.year + 1 : new Date().getFullYear()), []);

			alertService.addInfo(AlertKeys.YEAR_SUCCESS_CREATED);

			await dispatch(actionTypes.fetchYears);

			await router.push({ name: RouteNames.VACATION_PLANS_ADMINISTRATION_CALENDAR, params: { year: String(value) } }).catch(() => {
			});
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_YEAR_CREATING, false);
		}
	}
};

const mutations = <MutationTree<VacationPlanCalendarState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_YEAR](state, value) {
		state.filter.year = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new VacationPlanCalendarFilter(first(state.years)?.value);
	},
	[mutationTypes.SET_IS_YEARS_LOADING](state, value) {
		state.isYearsLoading = value;
	},
	[mutationTypes.SET_IS_YEAR_CREATING](state, value) {
		state.isYearCreating = value;
	},
	[mutationTypes.SET_YEARS](state, value) {
		state.years = value;
	},
	[mutationTypes.SET_CALENDAR_DATES](state, value) {
		state.calendarDates = value;
	},
	[mutationTypes.SET_IS_CALENDAR_LOADING](state, value) {
		state.isCalendarLoading = value;
	},
	[mutationTypes.SET_IS_CALENDAR_UPDATING](state, value) {
		state.isCalendarUpdating = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const vacationPlanHolidaysModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default vacationPlanHolidaysModule;
