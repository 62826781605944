import { convertToTimestamp } from "@/utils/dates";
import { ApiHrVacationApplicationApproval } from "@/api/hr/types/vacationApplication/apiHrVacationApplicationApproval";
import {
	HrVacationApplicationApprover,
	HrVacationApplicationApproverService
} from "@/types/hr/vacationApplication/hrVacationApplicationApprover";
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";

export interface HrVacationApplicationApproval {
	id: string;
	state: string;
	type: string;
	stateUpdatedAt: number;
	approvers: HrVacationApplicationApprover[];
	entityId: string;
	vacationApplicationId: string;
}

export class HrVacationApplicationApprovalService {
	static map(source: ApiHrVacationApplicationApproval): HrVacationApplicationApproval {
		return {
			...source,
			approvers: source.approvers.map(x => HrVacationApplicationApproverService.map(x)),
			stateUpdatedAt: convertToTimestamp(source.stateUpdatedAt) as number
		};
	}
}
