<template>
	<frp-dialog :value="value"
				v-if="value"
				:title="`${$t('dialogs.hrApplicationState.title')} ${employeeName}`"
				max-width="626"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #subtitle>
			<span class="mt-1 d-inline-block" style="font-size: 14px">
				{{ `${$t(`aliases.vacationType.${currentOpenedApplication.type}`)} - ${$tc("pluralization.day", daysCount)},` }}
			</span>
			<span class="ml-1">{{ currentOpenedApplication.period }}</span>
		</template>
		
		<template #content="{ onIntersect }">
			<span class="font-weight-bold primary--text mt-3" slot="font-size: 18px">{{ $t("dialogs.hrApplicationState.subtitle") }}</span>
			
			<v-divider class="mt-3"></v-divider>
			
			<hr-approval v-bind="currentOpenedApplication.approval"
						 :state="currentOpenedApplication.state"
						 :employee="currentOpenedApplication.employee"
						 :is-planned-vacation="currentOpenedApplication.type === ApiHrVacationTypeEnum.Planned"
						 no-created-at>
			</hr-approval>
		</template>

		<template #footer>
			<frp-btn elevation="0" color="blue" dark
					 @click="handleClose">
				{{ $t("buttons.close") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { ApiHrVacationTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationTypeEnum";
import HrApproval from "@/components/common/HrApproval.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { calculateVacationDays } from "@/store/hr/helpers/calculateVacationDays";
import vacationApplication from "@/store/hr/modules/vacationApplication";
import storeManager from "@/store/manager";
import { formatInitials } from "@/utils/formatting";
import { RouteNames } from "@/router/hr/routes";
import { actionTypes, getterTypes, mutationTypes } from "@/store/hr/modules/vacationApplications/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "@/mixins/colorsMixin";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import RussianNouns from "russian-nouns-js";

const LAST_NAME_CASE = "родительный";
const LAST_NAME_GENDER = "общий";

const namespace = storeManager.hr.vacationApplications.namespace;
const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			ApiHrVacationTypeEnum,
			namespace,
			russianNounsEngine: new RussianNouns.Engine()
		};
	},
	computed: {
		vacationApplication() {
			return vacationApplication
		},
		...mapState({
			currentOpenedApplication: state => state.currentOpenedApplication,
			calendarDates: state => state.calendarDates
		}),
		daysCount() {
			return calculateVacationDays(this.currentOpenedApplication, this.calendarDates);
		},
		employeeName() {
			const lastName = this.russianNounsEngine.decline({
				text: this.currentOpenedApplication.employee.lastName,
				gender: LAST_NAME_GENDER,
				surname: true
			}, LAST_NAME_CASE);
			const initials = formatInitials(this.currentOpenedApplication.employee);
			
			return `${lastName} ${initials}`;
		}
	},
	methods: {
		...mapMutations({
			setCurrentOpenedApplication: mutationTypes.SET_CURRENT_OPENED_APPLICATION
		}),
		handleClose() {
			this.setCurrentOpenedApplication(undefined);
			this.$emit("update:value", false);
		}
	},
	components: {
		HrApproval,
		FrpDialog,
		FrpBtn
	}
};
</script>
