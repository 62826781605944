const kpiUrl = process.env.VUE_APP_HR_KPI_URL;
const olkUrl = process.env.VUE_APP_OLK_URL;

export const externalUrls = {
	standards: "https://portal.frprf.ru/learning/gradebook.php",
	kpi: `${kpiUrl}/reporting`,
	kpiRegistry: `${kpiUrl}/registry`,
	expectations: {
		borrowerSettlementsRequest: "https://portal.frprf.ru/services/lists/297/element/0/0/?list_section_id=",
		lawyersRequest: "https://portal.frprf.ru/services/lists/282/element/0/0/?list_section_id=",
		itTechnicalSupport: "https://portal.frprf.ru/services/lists/241/element/0/0/?list_section_id=",
		vendorContractPaymentRequest: "https://portal.frprf.ru/frp/platezh/plata.php",
		internshipRequest: "https://portal.frprf.ru/frp/stag/post.php",
		businessTripRequest: "https://portal.frprf.ru/frp/komdok/komand.php"
	},
	promises: {
		bitrix: "https://portal.frprf.ru/redirect/tasks",

	},
	usefulLinks: {
		welcomebook: "/frp/welcomebook",
		teamly: "https://teamly.frprf.ru/space/cdf62100-a6a4-4464-9e98-54ad20294e9e/feed",
		informationSystems: `${olkUrl}/apps`,
		organizationalStructure: "https://portal.frprf.ru/frp/docs/struct.php",
		faq: "/frp/faq",
		billboard: "https://afishafrp.bitrix24.site",
		codex: "/frp/codex",
		callsDetails: "https://portal.frprf.ru/telephony/detail.php",
		doc: "/frp/doc"
	},
	learning: "https://portal.frprf.ru/learning",
	otherVacationApplicationTemplates: "https://portal.frprf.ru/~6Yr6U",
	manuals: {
		hrVacationPlans: "https://teamly.frprf.ru/space/cdf62100-a6a4-4464-9e98-54ad20294e9e/article/3384ba62-722b-41a6-ad18-27149fac66f3",
		hrVacationApplications: "https://teamly.frprf.ru/space/cdf62100-a6a4-4464-9e98-54ad20294e9e/article/ed04bf0a-eecd-4a93-b078-47e073f1cc63",
		hrVacationPlanEmployees: "https://teamly.frprf.ru/space/cdf62100-a6a4-4464-9e98-54ad20294e9e/article/c77039f6-371f-4ab2-8fad-891998a8a715"
	}
};
