<template>
	<v-card class="kpi-card pb-2">
		<v-form :readonly="isFormSaving || isFormReadonly"
				:disabled="isFormDisabled"
				:ref="refs.form"
				v-model="formValid">
			<frp-form-card>
				<frp-form-card-blocks>
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12">
							<span class="font-weight-bold" style="font-size: 24px">{{ $t("subheaders.vacationApplication") }}</span>
							<v-divider class="mt-5"></v-divider>
						</frp-form-card-block-col>
						
						<frp-form-card-block-col cols="12" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
							<frp-form-item>
								<bod-autocomplete v-model="vacationType"
												  :items="vacationTypes"
												  item-text="text"
												  item-value="value"
												  :disabled="!isCreateMode || !!$route.query.id"
												  color="blue"
												  required
												  :label="$t('fields.vacationType.label')"
												  :placeholder="$t('fields.vacationType.placeholder')">
								</bod-autocomplete>
								
								<span v-if="ApiHrVacationTypeEnum.Study === vacationType" style="font-size: 12px" class="mb-4">
									{{ $tc("content.hrStrictlyField") }}
								</span>
								
								<span v-if="datesRangeMinDate"
									  style="font-size: 12px" class="mb-4 warning--text font-weight-bold">
									{{ $t("content.hrVacationClosestAllowedStartDate", { date: formatDate(datesRangeMinDate, "d MMMM") }) }}
								</span>
							</frp-form-item>
							
							<frp-form-item v-if="ApiHrVacationTypeEnum.Planned === vacationType">
								<bod-autocomplete v-model="vacationId"
												  v-if="isCreateMode"
												  :items="formattedVacations"
												  item-text="text"
												  item-value="id"
												  color="blue"
												  :required="ApiHrVacationTypeEnum.Planned === vacationType"
												  :disabled="!isCreateMode || !!$route.query.id"
												  :label="$t('fields.plannedVacation.label')"
												  :placeholder="$t('fields.plannedVacation.placeholder')">
								</bod-autocomplete>
								
								<frp-text-field :value="applicationVacationDatesRange"
												v-else-if="ApiHrVacationTypeEnum.Donor !== vacationType"
												color="blue"
												disabled
												:label="$t('fields.plannedVacation.label')">
								</frp-text-field>
							</frp-form-item>
							
							<template v-if="ApiHrVacationTypeEnum.Donor !== vacationType">
								<frp-form-item>
									<hr-range-date-field v-model="datesRange"
														 v-if="isCreateMode || ApiHrVacationTypeEnum.Planned !== vacationType"
														 :max-days="365"
														 :year="year"
														 :min-date="datesRangeMinDate"
														 :max-date="unplannedVacationMaxDate"
														 :calendar-dates="calendarDates"
														 required
														 :disabled="!isCreateMode || ApiHrVacationTypeEnum.Planned === vacationType"
														 :label="$tc('fields.vacationPeriod.label')"
														 :placeholder="$tc('fields.vacationPeriod.placeholder')"
														 type="number">
									</hr-range-date-field>
								</frp-form-item>
								
								<frp-form-item>
									<frp-text-field :value="daysCount"
													color="blue"
													disabled
													:label="$tc('fields.daysCount.label')">
									</frp-text-field>
								</frp-form-item>
							</template>
							
							<template v-else>
								<frp-form-item>
									<frp-multiple-date-field v-model="vacationDays"
															 :calendar-dates="calendarDates"
															 :disabled="!isCreateMode"
															 :year="year"
															 required
															 max-days="2"
															 :label="$tc('fields.dayOrDays.label')"
															 :placeholder="$tc('fields.dayOrDays.placeholder')">
									</frp-multiple-date-field>
								</frp-form-item>
								
								<frp-form-item>
									<frp-date-field class="kpi-field" color="blue"
													v-model="bloodDonationDate"
													return-value-type="number"
													:disabled="!isCreateMode"
													:year="year"
													:min="startOfYear(Date.now())"
													:max="endOfYear(Date.now())"
													:calendar-dates="calendarDates"
													required
													:label="$tc('fields.bloodDonationDate.label')"
													:placeholder="$tc('fields.bloodDonationDate.placeholder')">
									</frp-date-field>
								</frp-form-item>
								
								<frp-form-item>
									<frp-text-field v-model="referenceNumber"
													color="blue"
													required
													:disabled="!isCreateMode"
													:label="$t('fields.referenceNumber.label')"
													:placeholder="$t('fields.referenceNumber.placeholder')">
									</frp-text-field>
								</frp-form-item>
								
								<frp-form-item>
									<frp-text-field v-model="referenceSeries"
													color="blue"
													required
													:disabled="!isCreateMode"
													:label="$t('fields.referenceSeries.label')"
													:placeholder="$t('fields.referenceSeries.placeholder')">
									</frp-text-field>
								</frp-form-item>
								
								<frp-form-item>
									<frp-date-field class="kpi-field" color="blue"
													v-model="referenceDay"
													return-value-type="number"
													:disabled="!isCreateMode"
													:year="year"
													:min="startOfYear(Date.now())"
													:max="endOfYear(Date.now())"
													:calendar-dates="calendarDates"
													required
													:label="$tc('fields.referenceDay.label')"
													:placeholder="$tc('fields.referenceDay.placeholder')">
									</frp-date-field>
								</frp-form-item>
							</template>
							
							<template v-if="ApiHrVacationTypeEnum.Study === vacationType">
								<frp-form-item>
									<frp-text-field v-model="educationPurpose"
													color="blue"
													required
													:disabled="!isCreateMode"
													:label="$t('fields.educationPurpose.label') + '*'"
													:placeholder="$t('fields.educationPurpose.placeholder')">
									</frp-text-field>
									<frp-text-field v-model="educationInstitution"
													color="blue"
													required
													:disabled="!isCreateMode"
													:label="$t('fields.educationInstitution.label') + '*'"
													:placeholder="$t('fields.educationInstitution.placeholder')">
									</frp-text-field>
									<frp-text-field v-model="educationDirection"
													color="blue"
													required
													:disabled="!isCreateMode"
													:label="$t('fields.educationDirection.label') + '*'"
													:placeholder="$t('fields.educationDirection.placeholder')">
									</frp-text-field>
								</frp-form-item>
							</template>
							
							<frp-form-item
								v-if="![ApiHrVacationTypeEnum.Donor, ApiHrVacationTypeEnum.ClinicalExamination].includes(vacationType)">
								<bod-autocomplete v-model="substituteId"
												  :items="formattedSubstitutes"
												  :disabled="!isCreateMode || !request.startDate"
												  :loading="isSubstitutesLoading"
												  item-text="fullName"
												  item-value="id"
												  color="blue"
												  :required="vacationType !== ApiHrVacationTypeEnum.Planned"
												  :label="$t('fields.substituteEmployee.label')"
												  :placeholder="$t('fields.substituteEmployee.placeholder')">
								</bod-autocomplete>
							</frp-form-item>
							
							<frp-form-item v-if="ApiHrVacationTypeEnum.ClinicalExamination === vacationType">
								<frp-high-autocomplete v-model="warrantType"
													   :disabled="!isCreateMode"
													   selectable-text
													   color="blue"
													   required
													   height="88"
													   :items="warrantTypes"
													   item-text="text"
													   item-value="value"
													   :label="$t('fields.warrantType.label')"
													   :placeholder="$t('fields.warrantType.placeholder')">
								</frp-high-autocomplete>
							</frp-form-item>
							
							<frp-form-item
								v-if="vacationType &&
									  ![ApiHrVacationTypeEnum.Planned, ApiHrVacationTypeEnum.Unplanned, ApiHrVacationTypeEnum.OwnExpense].includes(vacationType) &&
									  (ApiHrVacationTypeEnum.ClinicalExamination !== vacationType || warrantType === ApiHrClinicalExaminationWarrantTypeEnum.PrePension)">
								<span class="font-weight-bold mb-2">{{ $t(`content.vacationApplicationFile.${vacationType}.title`) }}</span>
								
								<span style="font-size: 14px" class="mb-4" v-if="isCreateMode">
									<span>{{ $t(`content.vacationApplicationFile.${vacationType}.text.part1`) }}</span>
									
									<template v-if="vacationType === ApiHrVacationTypeEnum.Other">
									<frp-link-btn :href="externalUrls.otherVacationApplicationTemplates"
												  class="d-inline-flex ml-1" :color="colors.blue.base">
										{{ $t(`content.vacationApplicationFile.${vacationType}.text.part2`) }}
									</frp-link-btn>
									<span class="ml-n1">
										{{ $t(`content.vacationApplicationFile.${vacationType}.text.part3`) }}
									</span>
									</template>
								</span>
								
								<frp-dropzone :type="HR_VACATION_APPLICATION"
											  v-if="isCreateMode"
											  background="white"
											  :formats="[]"
											  ref="dropzone"
											  @file:add="addFile"
											  @file:delete="deleteFile">
								</frp-dropzone>
								
								<template v-else>
									<div v-for="document in application.documents"
										 :key="document.id"
										 class="white darken-1 pa-4" style="border-right: 4px">
										<frp-file :name="document.title"
												  :id="document.fileId">
										</frp-file>
									</div>
								</template>
							</frp-form-item>
							
							<div v-if="isCreateMode && ApiHrVacationTypeEnum.Planned === vacationType">
								<span>{{ $t("content.createVacationApplicationText.part1") }}</span>
								<frp-router-link-btn :to="{ name: RouteNames.VACATION_PLANS, query: { employeeIds: currentUser.id } }"
													 target="_blank"
													 class="d-inline-flex ml-1" :color="colors.blue.base">
									{{ $t("content.createVacationApplicationText.part2") }}
								</frp-router-link-btn>
								<span class="ml-1">{{ $t("content.createVacationApplicationText.part3") }}.</span>
							</div>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-blocks>
				
				<v-row class="mt-5">
					<frp-form-actions v-if="isCreateMode"
									  :is-save-disabled="!stateContainsUnsavedChanges ||
											!isFormValid ||
											([ApiHrVacationTypeEnum.Other, ApiHrVacationTypeEnum.Donor, ApiHrVacationTypeEnum.Study].includes(vacationType) &&
											!request.tempApplicationFileId)"
									  :is-cancel-disabled="!stateContainsUnsavedChanges"
									  :is-loading-state="isFormLoadingState"
									  :submit-text="$t('buttons.sendForApproval')"
									  submit-color="blue"
									  @cancel="onFormCancel"
									  @submit="isSendToReviewDialogOpened = true"
									  :fixed="false">
					</frp-form-actions>
				</v-row>
			</frp-form-card>
		</v-form>
		
		<hr-send-application-to-review-confirm-dialog v-model="isSendToReviewDialogOpened"></hr-send-application-to-review-confirm-dialog>
	</v-card>
</template>

<script>
import { ApiHrClinicalExaminationWarrantTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrClinicalExaminationWarrantTypeEnum";
import { ApiHrVacationTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationTypeEnum";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpLinkBtn from "@/components/buttons/FrpLinkBtn.vue";
import FrpRouterLinkBtn from "@/components/buttons/FrpRouterLinkBtn.vue";
import FrpFile from "@/components/common/FrpFile.vue";
import BodAutocomplete from "@/components/fields/BodAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpHighAutocomplete from "@/components/fields/FrpHighAutocomplete.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpMultipleDateField from "@/components/fields/FrpMultipleDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpDropzone from "@/components/dropzone/FrpDropzone.vue";
import HrRangeDateField from "@/components/fields/HrRangeDateField.vue";
import FrpFormActions from "@/components/form/FrpFormActions.vue";
import FrpNestedContentLayout from "@/components/layouts/FrpNestedContentLayout.vue";
import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import FrpFormCard from "@/components/markup/FrpFormCard.vue";
import FrpFormCardBlock from "@/components/markup/FrpFormCardBlock.vue";
import FrpFormCardBlockCol from "@/components/markup/FrpFormCardBlockCol.vue";
import FrpFormCardBlockRow from "@/components/markup/FrpFormCardBlockRow.vue";
import FrpFormCardBlocks from "@/components/markup/FrpFormCardBlocks.vue";
import FrpFormItem from "@/components/markup/FrpFormItem.vue";
import { HR_VACATION_APPLICATION_STUDY_UNPLANNED_VACATION_MIN_INTERVAL_FROM_TODAY } from "@/constants/hr/date";
import { externalUrls } from "@/constants/hr/externalUrls";
import { HR_VACATION_APPLICATION } from "@/constants/storage";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { RouteNames } from "@/router/hr/routes";
import { calculateVacationDays } from "@/store/hr/helpers/calculateVacationDays";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/hr/modules/vacationApplication/types";
import { formatDate, parseDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatFullName } from "@/utils/formatting";
import HrSendApplicationToReviewConfirmDialog
	from "@/views/hr/vacationApplication/dialogs/HrSendApplicationToReviewConfirmDialog.vue";
import { addDays, addYears, endOfYear, getYear, startOfDay, startOfYear } from "date-fns";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, authorizationMixin, colorsMixin],
	data() {
		return {
			namespace,
			externalUrls,
			RouteNames,
			ApiHrVacationTypeEnum,
			ApiHrClinicalExaminationWarrantTypeEnum,
			HR_VACATION_APPLICATION,
			year: getYear(new Date()),
			isSendToReviewDialogOpened: false
		};
	},
	computed: {
		...mapState({
			initialized: state => state.isInitialized,
			years: state => state.years,
			request: state => state.request,
			vacations: state => state.vacations,
			application: state => state.application,
			substitutes: state => state.substitutes,
			isSubstitutesLoading: state => state.isSubstitutesLoading,
			calendarDates: state => state.calendarDates,
			vacationApplicationPermissions: state => state.permissions
		}),
		...mapGetters({
			currentUser: getterTypes.currentUser
		}),
		isCreateMode() {
			return !this.application?.id;
		},
		warrantTypes() {
			return Object.values(ApiHrClinicalExaminationWarrantTypeEnum)
						 .map(value => ({ text: this.$t(`aliases.clinicalExaminationWarrantType.${value}`), value }));
		},
		applicationVacationDatesRange() {
			return `${formatDate(this.application.startDate, dateFormat)}-${formatDate(this.application.endDate, dateFormat)}`;
		},
		vacationTypes() {
			const canCreateUnplanned = this.vacationApplicationPermissions.includes(this.Permissions.HR_VACATION_APPLICATION_CAN_CREATE_UNPLANNED);
			let types = Object.values(ApiHrVacationTypeEnum).filter(x => x !== ApiHrVacationTypeEnum.Other);
			
			if(this.isCreateMode && !canCreateUnplanned)
				types = types.filter(x => x !== ApiHrVacationTypeEnum.Unplanned);
			
			return types.map(x => ({ text: this.$t(`aliases.vacationType.${x}`), value: x }));
		},
		formattedVacations() {
			return this.vacations.map(x => ({
				...x,
				text: `${formatDate(x.startDate, dateFormat)} - ${formatDate(x.endDate, dateFormat)}`
			}));
		},
		formattedSubstitutes() {
			if(this.isCreateMode)
				return this.substitutes.map(x => ({ ...x, fullName: formatFullName(x) }));
			else if(this.application.vacationSubstitute)
				return [this.application.vacationSubstitute].map(x => ({ ...x, fullName: formatFullName(x) }));
			else
				return [];
		},
		vacationType: {
			get() {
				return this.isCreateMode ? this.request.vacationType : this.application.type;
			},
			set(value) {
				this.setRequestVacationType(value);
			}
		},
		vacationId: {
			get() {
				return this.request.plannedVacationId;
			},
			set(value) {
				this.setRequestVacationId(value);
			}
		},
		datesRange: {
			get() {
				const { startDate, endDate } = this.isCreateMode ? this.request : this.application;
				
				return [startDate, endDate];
			},
			set([startDate, endDate]) {
				this.setRequestStartDate(startDate);
				this.setRequestEndDate(endDate);
			}
		},
		substituteId: {
			get() {
				return this.isCreateMode ? this.request.vacationSubstituteEmployeeId : this.application.vacationSubstitute?.id;
			},
			set(value) {
				this.setRequestSubstituteId(value);
			}
		},
		bloodDonationDate: {
			get() {
				return this.isCreateMode ? this.request.bloodDonationDate : this.application.bloodDonationDate;
			},
			set(value) {
				this.setRequestBloodDonationDate(value);
			}
		},
		educationInstitution: {
			get() {
				return this.isCreateMode ? this.request.educationInstitution : this.application.educationInstitution;
			},
			set(value) {
				this.setRequestEducationInstitution(value);
			}
		},
		educationPurpose: {
			get() {
				return this.isCreateMode ? this.request.educationPurpose : this.application.educationPurpose;
			},
			set(value) {
				this.setRequestEducationPurpose(value);
			}
		},
		educationDirection: {
			get() {
				return this.isCreateMode ? this.request.educationDirection : this.application.educationDirection;
			},
			set(value) {
				this.setRequestEducationDirection(value);
			}
		},
		warrantType: {
			get() {
				return this.isCreateMode ? this.request.warrantType : this.application.warrantType;
			},
			set(value) {
				this.setRequestWarrantType(value);
			}
		},
		vacationDays: {
			get() {
				return this.isCreateMode ? this.request.vacationDays : this.application.vacationDays;
			},
			set(value) {
				this.setRequestVacationDays(value);
			}
		},
		referenceNumber: {
			get() {
				return this.isCreateMode ? this.request.referenceNumber : this.application.referenceNumber;
			},
			set(value) {
				this.setRequestReferenceNumber(value);
			}
		},
		referenceSeries: {
			get() {
				return this.isCreateMode ? this.request.referenceSeries : this.application.referenceSeries;
			},
			set(value) {
				this.setRequestReferenceSeries(value);
			}
		},
		referenceDay: {
			get() {
				return this.isCreateMode ? this.request.referenceDay : this.application.referenceDay;
			},
			set(value) {
				this.setRequestReferenceDay(value);
			}
		},
		daysCount() {
			const application = this.isCreateMode ? this.request : this.application;
			
			return application.startDate ? calculateVacationDays(application, this.calendarDates) : "-";
		},
		datesRangeMinDate() {
			if(!this.isCreateMode)
				return;
			
			if([ApiHrVacationTypeEnum.Study, ApiHrVacationTypeEnum.Unplanned].includes(this.vacationType))
				return startOfDay(addDays(new Date(), HR_VACATION_APPLICATION_STUDY_UNPLANNED_VACATION_MIN_INTERVAL_FROM_TODAY));
		},
		unplannedVacationMaxDate() {
			if(ApiHrVacationTypeEnum.Unplanned === this.vacationType)
				return endOfYear(addYears(Date.now(), 1));
		}
	},
	methods: {
		addYears,
		formatDate,
		endOfYear,
		startOfYear,
		parseDate,
		...mapMutations({
			setRequestVacationType: mutationTypes.SET_REQUEST_VACATION_TYPE,
			setRequestVacationId: mutationTypes.SET_REQUEST_VACATION_ID,
			setRequestStartDate: mutationTypes.SET_REQUEST_START_DATE,
			setRequestEndDate: mutationTypes.SET_REQUEST_END_DATE,
			setRequestSubstituteId: mutationTypes.SET_REQUEST_SUBSTITUTE_ID,
			setRequestFileId: mutationTypes.SET_REQUEST_FILE_ID,
			setRequestBloodDonationDate: mutationTypes.SET_REQUEST_BLOOD_DONATION_DATE,
			setRequestEducationInstitution: mutationTypes.SET_REQUEST_EDUCATION_INSTITUTION,
			setRequestEducationPurpose: mutationTypes.SET_REQUEST_EDUCATION_PURPOSE,
			setRequestEducationDirection: mutationTypes.SET_REQUEST_EDUCATION_DIRECTION,
			setRequestWarrantType: mutationTypes.SET_REQUEST_WARRANT_TYPE,
			setRequestVacationDays: mutationTypes.SET_REQUEST_VACATION_DAYS,
			setRequestReferenceNumber: mutationTypes.SET_REQUEST_REFERENCE_NUMBER,
			setRequestReferenceSeries: mutationTypes.SET_REQUEST_REFERENCE_SERIES,
			setRequestReferenceDay: mutationTypes.SET_REQUEST_REFERENCE_DAY,
			setSubstitutes: mutationTypes.SET_SUBSTITUTES
		}),
		...mapActions({
			save: actionTypes.save,
			fetchSubstitutes: actionTypes.fetchSubstitutes
		}),
		async addFile(file) {
			this.setRequestFileId(file.id);
		},
		deleteFile() {
			this.setRequestFileId("");
		}
	},
	watch: {
		async "request.startDate"(value) {
			if(value)
				await this.fetchSubstitutes();
		},
		async vacationType() {
			await this.$nextTick();
			
			this.vacationId = "";
			this.datesRange = [0, 0];
			
			this.$refs.form.resetValidation();
		},
		vacationId(value) {
			if(value) {
				const vacation = this.vacations.find(x => x.id === value);
				
				this.datesRange = [vacation.startDate, vacation.endDate];
			} else {
				this.datesRange = [0, 0];
			}
		},
		substitutes(value) {
			if(!value.some(x => x.id === this.substituteId))
				this.setRequestSubstituteId("");
		},
		"request.tempApplicationFileId"(value) {
			if(!value && this.$refs.dropzone)
				this.$refs.dropzone.clear();
		}
	},
	components: {
		FrpMultipleDateField,
		FrpDateField,
		FrpRouterLinkBtn,
		HrSendApplicationToReviewConfirmDialog,
		FrpFile,
		FrpLinkBtn,
		FrpBtn,
		FrpDropzone,
		HrRangeDateField,
		BodAutocomplete,
		FrpHighAutocomplete,
		FrpAutocomplete,
		KpiContentLayout,
		FrpTextField,
		FrpFormActions,
		FrpFormCardBlockCol,
		FrpFormCardBlocks,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormItem,
		FrpFormCard,
		FrpTextarea,
		FrpNestedContentLayout
	}
};
</script>
