<template>
	<router-link class="frp-link-btn d-flex"
				 :class="underline ? 'text-decoration-underline' : 'text-decoration-none'"
				 :style="{ color }"
				 :to="to"
				 :target="blank ? '_blank' : '_self'"
				 v-on="$listeners">
		<template v-if="icon">
			<frp-icon :src="icon"
					  :color="color"
					  class="mr-2"/>
		</template>
		<slot></slot>
	</router-link>
</template>

<script>
import FrpIcon from "@/components/icon/FrpIcon.vue";

export default {
	components: { FrpIcon },
	props: {
		icon: {
			type: String,
			default: undefined
		},
		color: {
			type: String
		},
		to: {
			type: Object,
			default: undefined
		},
		underline: Boolean,
		blank: Boolean
	}
};
</script>

<style scoped lang="less">
.frp-link-btn {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0;
	color: var(--v-primary-base)
}
</style>
