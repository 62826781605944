<template>
	<hr-content-layout container-class="pt-1 pt-lg-2 px-4 px-lg-4" bg-height="112">
		<v-card elevation="0" class="kpi-card px-6 py-6 mb-6">
			<div class="d-flex align-center mb-5">
				<span style="font-size: 24px" class="font-weight-bold primary--text">{{ $t("titles.absences") }}</span>
				<v-spacer></v-spacer>
				<frp-btn v-if="isAdmin"
						 @click="handleOpenNewAbsenceDialog"
						 :disabled="isCalendarLoading || isItemsLoading || !initialized"
						 color="blue" dark elevation="0">
					{{ $t("buttons.addAbsence") }}
				</frp-btn>
			</div>
			
			<div class="d-flex flex-wrap" style="gap: 16px">
				<bod-autocomplete class="kpi-field"
								  hide-details
								  item-value="value"
								  item-text="text"
								  @keydown.enter="applyFilter"
								  style="width: 290px"
								  multiple
								  :items="absenceTypes"
								  v-model="internalFilterValues.absenceTypes"
								  color="blue"
								  :placeholder="$t('fields.filterAbsenceTypes.placeholder')">
				</bod-autocomplete>
				
				<frp-tree-autocomplete item-text="name"
									   item-value="id"
									   class="kpi-field"
									   @keydown.enter="applyFilter"
									   multiple
									   :loading="isDepartmentsLoading"
									   style="width: 335px"
									   :items="departments"
									   required
									   v-model="internalFilterValues.departmentIds"
									   color="blue"
									   :placeholder="$t('fields.hrDepartment.placeholder')"
									   children-key="departments"
									   hide-details>
				</frp-tree-autocomplete>
				
				<bod-autocomplete item-text="fullName"
								  item-value="id"
								  @keydown.enter="applyFilter"
								  class="kpi-field"
								  multiple
								  :disabled="isFilterDepartmentsChanged"
								  @firstclick="fetchEmployees"
								  :loading="isEmployeesLoading"
								  style="width: 350px"
								  :items="employees"
								  v-model="internalFilterValues.employeeIds"
								  color="blue"
								  :placeholder="$t('fields.hrFilterEmployee.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<v-spacer></v-spacer>
				
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary" outlined elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged"
						 no-margin color="blue" dark elevation="0">
					{{ $t("buttons.apply") }}
				</frp-btn>
			</div>
		</v-card>
		
		<v-card elevation="0" class="kpi-card pa-0">
			<v-row>
				<v-col class="py-0">
					<frp-gantt-month :items="formattedItems"
									 @click:name="handleNameClick"
									 avatar
									 tasks-clickable
									 :loading="isCalendarLoading || isItemsLoading || !initialized"
									 @click:task="handleOpenAbsenceDialog"
									 @update:year="handleYearUpdated"
									 :calendar-dates="calendarDates">
					</frp-gantt-month>
				</v-col>
			</v-row>
		</v-card>
		
		<hr-absence-dialog v-model="isAbsenceDialogOpened"></hr-absence-dialog>
		<hr-new-absence-dialog v-model="isNewAbsenceDialogOpened" v-if="isNewAbsenceDialogOpened"></hr-new-absence-dialog>
	</hr-content-layout>
</template>

<script>
import { ApiHrAbsenceTypeEnum } from "@/api/hr/types/absence/ApiHrAbsenceTypeEnum";
import FrpFile from "@/components/common/FrpFile.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpGanttMonth from "@/components/gantt/FrpGanttMonth.vue";
import formMixin from "@/mixins/formMixin";
import HrAbsencesFilter from "@/store/hr/modules/absences/types/hrAbsencesFilter";
import { namespace as userNamespace } from "@/store/hr/modules/user";
import { openRouteInNewTab } from "@/utils/router";
import HrAbsenceDialog from "@/views/hr/absences/dialogs/HrAbsenceDialog.vue";
import HrNewAbsenceDialog from "@/views/hr/absences/dialogs/HrNewAbsenceDialog.vue";
import HrApplicationDocumentDialog from "@/views/hr/vacationApplications/dialogs/HrApplicationDocumentDialog.vue";
import HrApplicationStateDialog from "@/views/hr/vacationApplications/dialogs/HrApplicationStateDialog.vue";
import FrpLinkBtn from "@/components/buttons/FrpLinkBtn.vue";
import FrpRouterLinkBtn from "@/components/buttons/FrpRouterLinkBtn.vue";
import FrpPagination from "@/components/common/FrpPagination.vue";
import BodAutocomplete from "@/components/fields/BodAutocomplete.vue";
import FrpTreeAutocomplete from "@/components/fields/FrpTreeAutocomplete.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import BodContentLayout from "@/components/layouts/BodContentLayout.vue";
import FrpBottomSpace from "@/components/layouts/FrpBottomSpace.vue";
import HrContentLayout from "@/components/layouts/HrContentLayout.vue";
import { assign, isEqual } from "lodash";
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/hr/routes";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/hr/modules/absences/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "@/mixins/listMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const { mapState: mapUserState } = createNamespacedHelpers(userNamespace);

export default {
	mixins: [listMixin, formMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			RouteNames,
			namespace,
			internalFilterValues: {
				absenceTypes: [],
				departmentIds: [],
				employeeIds: [],
			},
			absenceTypes: Object.values(ApiHrAbsenceTypeEnum).map(x => ({ text: this.$t(`aliases.absenceType.${x}`), value: x })),
			isNewAbsenceDialogOpened: false,
			isAbsenceDialogOpened: false
		};
	},
	computed: {
		...mapUserState({
			isUserInitialized: state => state.isInitialized
		}),
		...mapState({
			initialized: state => state.isInitialized,
			isEmployeesLoading: state => state.isEmployeesLoading,
			isDepartmentsLoading: state => state.isDepartmentsLoading,
			isCalendarLoading: state => state.isCalendarLoading,
			departments: state => state.departments,
			employees: state => state.employees,
			currentOpenedAbsenceId: state => state.currentOpenedAbsenceId,
			calendarDates: state => state.calendarDates,
			newAbsence: state => state.newAbsence,
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isAdmin: getterTypes.isAdmin,
			currentUser: getterTypes.currentUser
		}),
		filter() {
			return {
				absenceTypes: this.internalFilterValues.absenceTypes,
				departmentIds: this.internalFilterValues.departmentIds,
				employeeIds: this.internalFilterValues.employeeIds
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new HrAbsencesFilter([this.currentUser.department?.id]), this.filterValues);
		},
		isFilterDepartmentsChanged() {
			return !isEqual(this.internalFilterValues.departmentIds, this.filterValues.departmentIds);
		},
	},
	methods: {
		...mapMutations({
			setFilterAbsenceTypes: mutationTypes.SET_FILTER_ABSENCE_TYPES,
			setFilterDepartmentIds: mutationTypes.SET_FILTER_DEPARTMENT_IDS,
			setFilterEmployeeIds: mutationTypes.SET_FILTER_EMPLOYEE_IDS,
			setFilter: mutationTypes.SET_FILTER,
			setCurrentOpenedAbsenceId: mutationTypes.SET_CURRENT_OPENED_ABSENCE_ID,
			resetNewAbsence: mutationTypes.RESET_NEW_ABSENCE,
			resetNewAbsenceType: mutationTypes.SET_NEW_ABSENCE_TYPE,
			resetNewAbsenceStartDate: mutationTypes.SET_NEW_ABSENCE_START_DATE,
			resetNewAbsenceEndDate: mutationTypes.SET_NEW_ABSENCE_END_DATE,
			resetNewAbsenceEmployeeId: mutationTypes.SET_NEW_ABSENCE_EMPLOYEE_ID,
			resetNewAbsenceComment: mutationTypes.SET_NEW_ABSENCE_COMMENT,
			setYear: mutationTypes.SET_YEAR,
		}),
		...mapActions({
			fetchCalendar: actionTypes.fetchCalendar,
			fetchEmployees: actionTypes.fetchEmployees,
			updateListingItems: actionTypes.updateListingItems,
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		resetFilter() {
			const defaults = new HrAbsencesFilter([this.currentUser.department.id]);
			this.setFilter(defaults);
		},
		applyFilter() {
			if(!this.internalFilterValues.departmentIds?.length)
				return;
			
			this.setFilterAbsenceTypes(this.internalFilterValues.absenceTypes || []);
			this.setFilterDepartmentIds(this.internalFilterValues.departmentIds || []);
			this.setFilterEmployeeIds(this.internalFilterValues.employeeIds || []);
		},
		handleOpenAbsenceDialog({ id }) {
			this.setCurrentOpenedAbsenceId(id);
			this.isAbsenceDialogOpened = true;
		},
		handleOpenNewAbsenceDialog() {
			this.isNewAbsenceDialogOpened = true;
		},
		async handleYearUpdated(year) {
			this.setYear(year);
			
			await Promise.all([
				this.updateListingItems(),
				this.fetchCalendar(),
			]);
		},
		handleNameClick(id) {
			openRouteInNewTab(this.$router, { name: RouteNames.EMPLOYEE, params: { id } });
		}
	},
	watch: {
		isUserInitialized: {
			handler(value) {
				if(value)
					this.initializeStore();
			},
			immediate: true
		}
	},
	components: {
		HrNewAbsenceDialog,
		HrAbsenceDialog,
		FrpGanttMonth,
		HrApplicationDocumentDialog,
		HrApplicationStateDialog,
		FrpFile,
		FrpDateField,
		FrpTextarea,
		FrpTreeAutocomplete,
		FrpRouterLinkBtn,
		FrpLinkBtn,
		FrpBottomSpace,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpTextField,
		BodContentLayout,
		HrContentLayout
	}
};
</script>
