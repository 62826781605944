import SubscribersManager from "@/store/manager/subscribersManager";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/hr/modules/vacationPlans/types";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/hr/routes";
import router from "@/router/hr";
import routeTypes from "@/store/shared/route/types";
import { cloneDeep, first, isEqual } from "lodash";
import { formatFullName } from "@/utils/formatting";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { HrController } from "@/api/hr";
import { ApiHrGetEmployeesParameters } from "@/api/hr/types/apiHrGetEmployeesParameters";
import VacationPlansFilter from "@/store/hr/modules/vacationPlans/types/vacationPlansFilter";
import VacationPlansState from "@/store/hr/modules/vacationPlans/types/vacationPlansState";
import VacationPlansRouteService from "@/store/hr/modules/vacationPlans/services/vacationPlansRouteService";
import VacationPlansRouteQuery from "@/store/hr/modules/vacationPlans/types/vacationPlansRouteQuery";
import VacationPlansRouteParams from "@/store/hr/modules/vacationPlans/types/vacationPlansRouteParams";
import { HrNewVacation, HrNewVacationService } from "@/types/hr/vacation/hrNewVacation";
import { HrAcceptPlanRequestService } from "@/types/hr/vacation/hrAcceptPlanRequest";
import { HrVacationPlansPageModeEnum } from "@/store/hr/modules/vacationPlans/types/HrVacationPlansPageModeEnum";
import { HrVacationPlansItem, HrVacationPlansItemMapper } from "@/types/hr/vacation/hrVacationPlansItem";
import { HrVacationPlan, HrVacationPlanService } from "@/types/hr/vacation/hrVacationPlan";
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import { ApiHrNewVacationMapper } from "@/api/hr/types/vacation/apiHrNewVacation";
import { ApiHrAcceptPlanRequestMapper } from "@/api/hr/types/vacation/apiHrAcceptPlanRequest";
import { getVacationsWithDays } from "@/store/hr/helpers/calculateVacationDays";
import {
	HrFormattedVacationPlansItem,
	HrFormattedVacationPlansItemMapper
} from "@/store/hr/modules/vacationPlans/types/hrVacationPlansItem";
import { HrVacationPlanViewEnum } from "@/types/hr/vacation/HrVacationPlanViewEnum";
import { ApiHrVacationPlansItem } from "@/api/hr/types/vacation/apiHrVacationPlansItem";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import { i18n } from "@/plugins";
import storeManager from "@/store/manager";
import UserState from "@/store/hr/modules/user/types/userState";
import { VacationPlansSnapshotKeysEnum } from "@/store/hr/modules/vacationPlans/types/VacationPlansSnapshotKeysEnum";
import { HrVacation } from "@/types/hr/vacation/hrVacation";
import contentDispositionParser from "content-disposition-parser";
import { saveAs } from "file-saver";
import { findParentIds, flatSubsWithLevels } from "@/utils/array";
import { getHrPermissions } from "@/store/hr/modules/vacationPlans/helpers/getPermissions";
import { Permissions } from "@/constants/permissions";
import { getHrDefaultYear } from "@/views/hr/helpers/getHrDefaultYear";
import { checkIsVacationImmutable } from "@/store/hr/modules/vacationPlans/helpers/CheckIsVacationImmutable";
import { HrVacationApplicationApprovalService } from "@/types/hr/vacationApplication/hrVacationApplicationApproval";
import { CalendarDateHelper } from "@/types/calendar/calendarDate";
import { CalendarController } from "@/api/calendar";
import { addDays, startOfDay } from "date-fns";
import { HR_VACATION_PLANS_VACATION_REPLACEMENT_MIN_INTERVAL_FROM_TODAY } from "@/constants/hr/date";

const abortService = new AbortService();
const hrController = new HrController(abortService);
const calendarController = new CalendarController(abortService);

const defaultRouteQuery = new VacationPlansRouteQuery();
const defaultRouteParams = new VacationPlansRouteParams();

const routeService = new VacationPlansRouteService(defaultRouteQuery, defaultRouteParams);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<VacationPlansState>()).build();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: VacationPlansSnapshotKeysEnum.NEW_VACATIONS,
			fields: ["newVacations"]
		}),
		new SnapshotOptions({
			key: VacationPlansSnapshotKeysEnum.EDITABLE_VACATION,
			fields: ["editableVacation"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new VacationPlansState(
			new ListingModel<HrVacationPlansItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "startDate",
				order: sortingOrderType.ascending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			new VacationPlansFilter(),
			routeMixin.state(),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<VacationPlansState>;

type Version = { title: string, strikethrough: boolean } & HrVacationPlan;
const getters = <GetterTree<VacationPlansState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: (state): HrFormattedVacationPlansItem[] => {
		return state.listing.items.map(x => HrFormattedVacationPlansItemMapper.map(x));
	},
	[getterTypes.filteredFormattedItems]: (state, getters): HrFormattedVacationPlansItem[] => {
		const filteredEmployees = (getters[getterTypes.formattedItems] as HrFormattedVacationPlansItem[]).filter(x => {
			if(state.filter.departmentIds.length) {
				const flattedDepartments = flatSubsWithLevels("departments", state.departments);
				
				if(state.filter.departmentIds.length && ![
					...findParentIds(flattedDepartments, x.employee.department), x.employee.department.id
				].some(y => state.filter.departmentIds.includes(y)))
					return false;
			}
			
			return !state.filter.employeeIds.length || state.filter.employeeIds.includes(x.employee.id);
		});
		
		return filteredEmployees.map(x => {
			return {
				...x,
				vacationPlan: x.vacationsPlans.find(x => state.filter.versionId ?
					x.id === state.filter.versionId :
					x.state === HrVacationPlanStateEnum.APPROVED) || first(x.vacationsPlans) || null
			};
		});
	},
	[getterTypes.yearValues](state): number[] {
		return state.years.map(x => x.value);
	},
	[getterTypes.allowedNewVacationDaysCount](state): number {
		if(state.mode !== HrVacationPlansPageModeEnum.EDIT) return 0;
		
		const currentVacationDays = state.editableVacation.days;
		const totalSelectedDays = state.newVacations.reduce((acc, v) => acc + v.days, 0);
		const totalVacationDays = state.listing.items.find(x => x.employee.id === state.filter.employeeIds[0])!.totalVacationDays;
		
		return totalVacationDays - totalSelectedDays + currentVacationDays;
	},
	[getterTypes.editableVacationSiblingVacations](state, getters): HrNewVacation[] {
		return state.newVacations.filter(x => x.previousVacationId === state.editableVacation.previousVacationId);
	},
	[getterTypes.editableVacationParentVacation](state): HrVacation | undefined {
		return state.parentVacations.find(x => x.id === state.editableVacation.previousVacationId);
	},
	[getterTypes.hasLongVacation](state): boolean | undefined {
		if(state.mode !== HrVacationPlansPageModeEnum.EDIT) return;
		
		return state.newVacations.some(x => x.days >= 14);
	},
	[getterTypes.vacationsDaysCount](state): number | undefined {
		if(state.mode !== HrVacationPlansPageModeEnum.EDIT) return;
		
		return state.newVacations.map(x => x.days).reduce((acc, v) => acc + v, 0);
	},
	[getterTypes.isVacationDaysEnough](state, getters): boolean | undefined {
		if(state.mode !== HrVacationPlansPageModeEnum.EDIT) return;
		
		const employee = getters[getterTypes.currentFilterEmployee] as HrVacationPlansItem | undefined;
		
		return getters[getterTypes.vacationsDaysCount] === employee?.totalVacationDays;
	},
	[getterTypes.currentFilterEmployee](state): HrVacationPlansItem | undefined {
		if(state.filter.employeeIds.length !== 1) return;
		
		return state.listing.items.find(x => x.employee.id === first(state.filter.employeeIds));
	},
	[getterTypes.currentUser](state, getters, rootState): ApiHrEmployee {
		return resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).user;
	},
	[getterTypes.currentUserPermissions](state, getters, rootState): Permissions[] {
		const roles = resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).roles;
		
		return getHrPermissions(roles);
	},
	[getterTypes.canAcceptOwnPlan](state, getters): boolean {
		const permissions = (getters[getterTypes.currentUserPermissions] as Permissions[]);
		
		return [Permissions.OWN_HR_VACATIONS_PLAN_APPROVE, Permissions.OWN_HR_VACATIONS_PLAN_DECLINE].some(x => permissions.includes(x));
	},
	[getterTypes.editablePlan](state, getters): HrVacationPlan | undefined {
		if(state.mode !== HrVacationPlansPageModeEnum.EDIT) return;
		
		return (getters[getterTypes.currentUserVersions] as Version[]).find(x => x.id === state.filter.versionId);
	},
	[getterTypes.currentUserVersions](_, getters): Version[] {
		if(!getters[getterTypes.currentFilterEmployee]) return [];
		
		const employeePlans = cloneDeep((getters[getterTypes.currentFilterEmployee] as HrVacationPlansItem).vacationsPlans);
		const reversed = employeePlans.reverse();
		const lastApprovedId = reversed.filter(x => x.state === HrVacationPlanStateEnum.APPROVED).pop()?.id;
		
		return reversed.map((x, i) => ({
			...x,
			strikethrough: (x.state === HrVacationPlanStateEnum.APPROVED && x.id !== lastApprovedId) || x.state ===
				HrVacationPlanStateEnum.REJECTED,
			title: `${i18n.t("common.version")} ${++i} (${i18n.t(`aliases.vacationPlanState.${x.state}`)})`
		}));
	},
	[getterTypes.isAllVacationsHaveApplications](state) {
		return state.newVacations.every(vacationDay => (checkIsVacationImmutable(vacationDay) || vacationDay.factDate));
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<VacationPlansState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<VacationPlansState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !mutation.payload.to.query.year) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.reconstituteRoute));
			} else if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_MONTH):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_DEPARTMENT_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_VERSION_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_VIEW):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_EMPLOYEE_IDS):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			break;
		}
		case resolveMutation(namespace, mutationTypes.SET_FILTER_YEAR):
		case resolveMutation(namespace, mutationTypes.SET_FILTER):
		{
			if(!state.isInitialized)
				return;
			
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			updateListingBatchService.push(async () => {
				await Promise.all([
					subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems)),
					subscribersManager.dispatch(resolveAction(namespace, actionTypes.fetchCalendar)),
					subscribersManager.dispatch(resolveAction(namespace, actionTypes.fetchGanttEmployees))
				]);
			});
			
			break;
		}
	}
};

const actions = <ActionTree<VacationPlansState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, getters, state }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.processRoute);
		await dispatch(actionTypes.reconstituteRoute);
		
		if(state.filter.view === HrVacationPlanViewEnum.UNKNOWN)
			subscribersManager.commit(resolveMutation(namespace, mutationTypes.SET_FILTER_VIEW), HrVacationPlanViewEnum.CALENDAR);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		
		await Promise.all([
			dispatch(actionTypes.fetchYears),
			dispatch(actionTypes.fetchDepartments)
		]);
		
		await Promise.all([
			dispatch(actionTypes.fetchCalendar),
			dispatch(actionTypes.updateListingItems),
			dispatch(actionTypes.fetchGanttEmployees)
		]);
		
		if(state.filter.employeeIds.length === 1) {
			const permissions = (getters[getterTypes.currentUserPermissions] as Permissions[]);
			const user = (getters[getterTypes.currentUser] as ApiHrEmployee);
			const currentItem = (getters[getterTypes.filteredFormattedItems] as HrFormattedVacationPlansItem[])[0];
			
			if((permissions.includes(Permissions.HR_VACATIONS_PLAN_UPDATE) ||
					currentItem?.employee.id === user.id) &&
				currentItem?.vacationPlan?.state === HrVacationPlanStateEnum.DRAFT)
				await dispatch(actionTypes.editDraft, { employeeId: currentItem.employee.id, versionId: currentItem.vacationPlan.id });
		}
		
		commit(mutationTypes.SET_VACATION_REPLACEMENT_MIN_DATE,
			startOfDay(addDays(new Date(), HR_VACATION_PLANS_VACATION_REPLACEMENT_MIN_INTERVAL_FROM_TODAY)));
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		
		await dispatch(actionTypes.updateDefaultRoute);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		if(!state.filter.year)
			return;
		
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			let items: ApiHrVacationPlansItem[];
			
			items = await hrController.getVacationPlans(String(state.filter.year));
			
			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => HrVacationPlansItemMapper.map(x)));
			
			await dispatch(actionTypes.fetchUserVersions);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeService.resolveRouteQuery(rootState.route.query);
		let routeParams = await routeService.resolveRouteParams(rootState.route.params);
		
		// Если при открытии страницы уже стоят фильтры, нужно сразу загрузить соответствующие справочники
		if(routeQuery.employeeIds.length)
			await dispatch(actionTypes.fetchEmployees);
		
		if(state.filter.year !== routeParams.year)
			commit(mutationTypes.SET_FILTER_YEAR, routeParams.year);
		if(!isEqual(state.filter.employeeIds, routeQuery.employeeIds))
			commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, routeQuery.employeeIds);
		
		commit(mutationTypes.SET_FILTER_MONTH, routeQuery.month);
		commit(mutationTypes.SET_FILTER_DEPARTMENT_IDS, routeQuery.departmentIds);
		commit(mutationTypes.SET_FILTER_VERSION_ID, routeQuery.versionId);
		commit(mutationTypes.SET_FILTER_VIEW, routeQuery.view);
		commit(mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES, routeQuery.isAllEmployees);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.VACATION_PLANS,
			params: routeService.resolveRouteParamsDictionary(state),
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		if(state.isDestroyed)
			return;
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.VACATION_PLANS,
			params: routeService.resolveRouteParamsDictionary(state),
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchDepartments]({ commit, state, dispatch }) {
		if(state.departments.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, true);
		
		try {
			const items = await hrController.getDepartments();
			
			commit(mutationTypes.SET_DEPARTMENTS, items);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, false);
		}
	},
	async [actionTypes.fetchGanttEmployees]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_GANTT_EMPLOYEES_LOADING, true);
		
		try {
			const items = await hrController.getGanttEmployees(String(state.filter.year));
			
			commit(mutationTypes.SET_GANTT_EMPLOYEES, items);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_GANTT_EMPLOYEES_LOADING, false);
		}
	},
	async [actionTypes.fetchEmployees]({ commit, state, dispatch }) {
		if(state.employees.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, true);
		
		try {
			const { employees } = await hrController.getEmployees({} as ApiHrGetEmployeesParameters);
			
			commit(mutationTypes.SET_EMPLOYEES, employees.map(x => ({ ...x, fullName: formatFullName(x) })));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_EMPLOYEES_LOADING, false);
		}
	},
	async [actionTypes.fetchYears]({ commit, state, dispatch }) {
		if(state.years.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_YEARS_LOADING, true);
		
		try {
			const items = await hrController.getVacationPlanYears();
			
			commit(mutationTypes.SET_YEARS, items);
			
			if(!router.currentRoute.params.year || !items.some(x => x.value === +router.currentRoute.params.year))
				commit(mutationTypes.SET_FILTER_YEAR, getHrDefaultYear(items)?.value);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_YEARS_LOADING, false);
		}
	},
	async [actionTypes.fetchCalendar]({ commit, state, dispatch }) {
		if(!state.filter.year)
			return;
		
		commit(mutationTypes.SET_IS_CALENDAR_LOADING, true);
		
		try {
			const { dates } = await calendarController.getWorkingDaysAndHolidays(state.filter.year);
			
			commit(mutationTypes.SET_CALENDAR_DATES, dates.map(x => CalendarDateHelper.map(x)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_CALENDAR_LOADING, false);
		}
	},
	async [actionTypes.editDraft]({ commit, state, getters }, { employeeId, versionId }: { employeeId: string, versionId: string }) {
		if(!state.filter.versionId || state.filter.versionId !== versionId) {
			commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, [employeeId]);
			commit(mutationTypes.SET_FILTER_VERSION_ID, versionId);
		}
		
		const item = state.listing.items.find(x => x.employee.id === employeeId);
		
		const version = item!.vacationsPlans.find(x => x.id === versionId)!;
		const parentVersion = item!.vacationsPlans.find(x => x.state === HrVacationPlanStateEnum.APPROVED);
		
		commit(mutationTypes.SET_NEW_VACATIONS, getVacationsWithDays(version.plannedVacations, state.calendarDates));
		
		if(parentVersion)
			commit(mutationTypes.SET_PARENT_VACATIONS,
				getVacationsWithDays(parentVersion.plannedVacations.filter((x => !checkIsVacationImmutable(x))),
					state.calendarDates));
		
		commit(mutationTypes.SET_EDITABLE_PLAN_ID, version.id);
		commit(mutationTypes.SET_MODE, HrVacationPlansPageModeEnum.EDIT);
		commit(mutationTypes.SET_STATE_SNAPSHOT, VacationPlansSnapshotKeysEnum.NEW_VACATIONS);
	},
	async [actionTypes.createNewDraft]({ commit, state, getters, dispatch },
		{ employeeId, versionId }: { employeeId: string, versionId: string })
	{
		commit(mutationTypes.SET_IS_LOADING, true);
		
		try {
			if(!state.filter.versionId || state.filter.versionId !== versionId) {
				commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, [employeeId]);
				commit(mutationTypes.SET_FILTER_VERSION_ID, versionId);
			}
			
			const item = state.listing.items.find(x => x.employee.id === employeeId);
			
			// Проверка, создан ли уже черновик. Если да, то открывается существующий
			const draft = item!.vacationsPlans.find(x => x.state === HrVacationPlanStateEnum.DRAFT);
			
			if(draft) {
				await dispatch(actionTypes.editDraft, { employeeId, versionId: draft.id });
				return;
			}
			
			const parentVersion = item!.vacationsPlans.find(x => x.id === versionId)!;
			const vacations = getVacationsWithDays(parentVersion.plannedVacations.map(x => ({ ...x, previousVacationId: x.id })),
				state.calendarDates);
			const parentVacations = getVacationsWithDays(parentVersion.plannedVacations
																	  .filter((x => !checkIsVacationImmutable(x) && !x.factDate))
																	  .map(x => ({ ...x, previousVacationId: x.id })),
				state.calendarDates);
			
			commit(mutationTypes.SET_NEW_VACATIONS, vacations);
			commit(mutationTypes.SET_PARENT_VACATIONS, parentVacations);
			commit(mutationTypes.SET_MODE, HrVacationPlansPageModeEnum.EDIT);
			commit(mutationTypes.SET_STATE_SNAPSHOT, VacationPlansSnapshotKeysEnum.NEW_VACATIONS);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	},
	async [actionTypes.createFirstDraft]({ commit, state, dispatch }, { employeeId }: { employeeId: string }) {
		if(!state.filter.versionId)
			commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, [employeeId]);
		
		commit(mutationTypes.SET_NEW_VACATIONS, []);
		commit(mutationTypes.SET_MODE, HrVacationPlansPageModeEnum.EDIT);
		commit(mutationTypes.SET_STATE_SNAPSHOT, VacationPlansSnapshotKeysEnum.NEW_VACATIONS);
	},
	async [actionTypes.deleteDraft]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_DRAFT_DELETING, true);
		
		try {
			await hrController.deleteDraft(String(state.filter.year), first(state.filter.employeeIds)!, state.filter.versionId!);
			
			await dispatch(actionTypes.setReadMode);
			
			commit(mutationTypes.REMOVE_CURRENT_PLAN);
			commit(mutationTypes.SET_FILTER_VERSION_ID, null);
			
			alertService.addInfo(AlertKeys.VACATION_PLAN_SUCCESS_DELETED);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DRAFT_DELETING, false);
		}
	},
	async [actionTypes.sendToReview]({ commit, state, dispatch, getters }) {
		commit(mutationTypes.SET_IS_SENDING_TO_REVIEW, true);
		
		const employeeId = first(state.filter.employeeIds)!;
		
		try {
			await hrController.sendVacationPlanToReview(String(state.filter.year),
				employeeId,
				state.filter.versionId!);
			
			await dispatch(actionTypes.setReadMode);
			await dispatch(actionTypes.updateListingItems);
			
			commit(mutationTypes.SET_CURRENT_PLAN_STATE, { employeeId, value: HrVacationPlanStateEnum.IN_REVIEW });
			
			alertService.addInfo(AlertKeys.VACATION_PLAN_SENT_TO_REVIEW);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_SENDING_TO_REVIEW, false);
		}
	},
	async [actionTypes.saveDraft]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		
		try {
			const employeeId = first(state.filter.employeeIds)!;
			const item = state.listing.items.find(x => x.employee.id === employeeId)!;
			const isNewDraft: boolean = !state.editableVersionId;
			const lastApprovedPlan = item.vacationsPlans.find(x => x.state === HrVacationPlanStateEnum.APPROVED);
			const previousVacationsPlanId: string = lastApprovedPlan?.id ?? "";
			
			const vacations = state.newVacations.filter(x => !checkIsVacationImmutable(x) && !x.factDate);
			
			const plan = await hrController.saveVacationPlan(String(state.filter.year), employeeId, {
				vacations: vacations.map(x => ApiHrNewVacationMapper.map(x)),
				previousVacationsPlanId
			});
			
			const mappedPlan = HrVacationPlanService.map(plan);
			
			if(isNewDraft)
				commit(mutationTypes.ADD_ITEMS_PLAN, { employeeId: first(state.filter.employeeIds), value: mappedPlan });
			else
				commit(mutationTypes.SET_ITEMS_PLAN,
					{ employeeId: first(state.filter.employeeIds), planId: state.editableVersionId, value: mappedPlan });
			
			commit(mutationTypes.SET_FILTER_VERSION_ID, plan.id);
			commit(mutationTypes.SET_EDITABLE_PLAN_ID, plan.id);
			commit(mutationTypes.SET_STATE_SNAPSHOT, VacationPlansSnapshotKeysEnum.NEW_VACATIONS);
			
			alertService.addInfo(AlertKeys.VACATION_PLAN_SUCCESS_SAVED);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.saveAndApproveDraft]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_SAVING_AND_APPROVING, true);
		
		try {
			const employeeId = first(state.filter.employeeIds)!;
			const item = state.listing.items.find(x => x.employee.id === employeeId)!;
			const isNewDraft: boolean = !state.editableVersionId;
			const lastApprovedPlan = item.vacationsPlans.find(x => x.state === HrVacationPlanStateEnum.APPROVED);
			const previousVacationsPlanId: string = lastApprovedPlan?.id ?? "";
			
			const vacations = state.newVacations.filter(x => !checkIsVacationImmutable(x) && !x.factDate);
			
			const plan = await hrController.saveAndApproveVacationPlan(String(state.filter.year), first(state.filter.employeeIds)!, {
				vacations: vacations.map(x => ApiHrNewVacationMapper.map(x)),
				previousVacationsPlanId
			});
			
			const mappedPlan = HrVacationPlanService.map(plan);
			
			if(!isNewDraft)
				commit(mutationTypes.SET_ITEMS_PLAN,
					{ employeeId: first(state.filter.employeeIds), planId: state.editableVersionId, value: mappedPlan });
			else
				commit(mutationTypes.ADD_ITEMS_PLAN, { employeeId: first(state.filter.employeeIds), value: mappedPlan });
			
			commit(mutationTypes.SET_FILTER_VERSION_ID, plan.id);
			
			await dispatch(actionTypes.setReadMode);
			
			alertService.addInfo(AlertKeys.VACATION_PLAN_SUCCESS_SAVED);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_SAVING_AND_APPROVING, false);
		}
	},
	async [actionTypes.approvePlans]({ commit, state, dispatch, getters }) {
		commit(mutationTypes.SET_IS_APPROVING, true);
		
		try {
			const currentUserId = (getters[getterTypes.currentUser] as ApiHrEmployee).id;
			const employees = (getters[getterTypes.filteredFormattedItems] as HrFormattedVacationPlansItem[]).filter(x => {
				return x.vacationsPlans.length === 1 &&
					((getters[getterTypes.canAcceptOwnPlan] as boolean) || x.employee.id !== currentUserId);
			});
			const plans = employees.map(x => x.vacationsPlans).flat();
			const planIds = plans.filter((x) => x.state === HrVacationPlanStateEnum.IN_REVIEW).map(x => x.id);
			
			await hrController.approveVacationPlans(String(state.filter.year), planIds);
			
			await dispatch(actionTypes.updateListingItems);
			
			alertService.addInfo(AlertKeys.VACATION_PLANS_SUCCESS_APPROVED);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_APPROVING, false);
		}
	},
	async [actionTypes.approvePlan]({ commit, state, dispatch }, planId: string) {
		commit(mutationTypes.SET_IS_APPROVING, true);
		
		try {
			await hrController.approveVacationPlan(String(state.filter.year), ApiHrAcceptPlanRequestMapper.map(state.acceptPlanRequest));
			
			await dispatch(actionTypes.updateListingItems);
			
			alertService.addInfo(AlertKeys.VACATION_PLAN_SUCCESS_APPROVED);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_APPROVING, false);
		}
	},
	async [actionTypes.declinePlan]({ commit, state, dispatch }, planId: string) {
		commit(mutationTypes.SET_IS_DECLINING, true);
		
		try {
			await hrController.declineVacationPlan(String(state.filter.year), ApiHrAcceptPlanRequestMapper.map(state.acceptPlanRequest));
			
			await dispatch(actionTypes.updateListingItems);
			
			alertService.addInfo(AlertKeys.VACATION_PLAN_SUCCESS_DECLINED);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DECLINING, false);
		}
	},
	async [actionTypes.withdrawPlan]({ commit, state, dispatch }, { employeeId, versionId }: { employeeId: string, versionId: string }) {
		commit(mutationTypes.SET_IS_WITHDRAWING, true);
		
		try {
			await hrController.withdrawVacationPlan(String(state.filter.year), employeeId, versionId);
			
			commit(mutationTypes.SET_CURRENT_PLAN_STATE, { employeeId, value: HrVacationPlanStateEnum.DRAFT });
			
			await dispatch(actionTypes.updateListingItems);
			
			alertService.addInfo(AlertKeys.VACATION_PLAN_SUCCESS_WITHDRAWN);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_WITHDRAWING, false);
		}
	},
	async [actionTypes.exportPlans]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_EXPORTING, true);
		
		try {
			const { data, responseHeaders } = await hrController.exportPlans(String(state.filter.year));
			const { filename } = contentDispositionParser(responseHeaders["content-disposition"]);
			
			saveAs(data, filename);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_EXPORTING, false);
		}
	},
	async [actionTypes.fetchUserVersions]({ commit, state, dispatch, getters }) {
		const currentUser = getters[getterTypes.currentUser] as ApiHrEmployee;
		
		try {
			const item = state.listing.items.find(x => x.employee.id === currentUser.id);
			const plans = item?.vacationsPlans || [];
			const lastApproved = plans.find(x => x.state === HrVacationPlanStateEnum.APPROVED) || first(plans);
			
			if(plans)
				commit(mutationTypes.SET_CURRENT_USER_PLANS, plans);
			
			await dispatch(actionTypes.updateDefaultRoute);
			
			if(currentUser.department?.id && !state.filter.departmentIds.length)
				commit(mutationTypes.SET_FILTER_DEPARTMENT_IDS, [currentUser.department.id]);
			if(currentUser.id && !state.filter.isAllEmployees && !state.filter.employeeIds.length)
				commit(mutationTypes.SET_FILTER_EMPLOYEE_IDS, [currentUser.id]);
			if(state.filter.employeeIds.length === 1 && lastApproved && !state.filter.versionId)
				commit(mutationTypes.SET_FILTER_VERSION_ID, lastApproved.id);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		}
	},
	async [actionTypes.fetchApproval]({ commit, state, dispatch, getters }, approvalId: string) {
		commit(mutationTypes.SET_LOADING_APPROVAL_ID, approvalId);
		
		try {
			const approval = await hrController.getApproval(approvalId);
			
			commit(mutationTypes.SET_APPROVAL, HrVacationApplicationApprovalService.map(approval));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_LOADING_APPROVAL_ID, "");
		}
	},
	async [actionTypes.removeApprover]({ commit, state, dispatch, getters }, approverId: string) {
		commit(mutationTypes.SET_IS_APPROVER_REMOVING, true);
		
		try {
			await hrController.removeApprover(state.approval!.id, approverId);
			
			await Promise.all([
				dispatch(actionTypes.updateListingItems),
				dispatch(actionTypes.fetchApproval, state.approval!.id)
			]);
			
			alertService.addInfo(AlertKeys.APPROVER_SUCCESS_REMOVED);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_APPROVER_REMOVING, false);
		}
	},
	async [actionTypes.updateDefaultRoute]({ state, getters }) {
		if(!state.isInitialized || !state.filter.year)
			return;
		
		const currentUser = getters[getterTypes.currentUser] as ApiHrEmployee;
		const item = state.listing.items.find(x => x.employee.id === currentUser.id);
		const lastApproved = (item?.vacationsPlans || []).find(x => x.state === HrVacationPlanStateEnum.APPROVED);
		
		const defaultRouteQuery = new VacationPlansRouteQuery(
			[currentUser.department.id],
			[currentUser.id],
			!lastApproved ? null : lastApproved!.id,
			null,
			HrVacationPlanViewEnum.CALENDAR
		);
		const defaultRouteParams = new VacationPlansRouteParams(getHrDefaultYear(state.years)?.value);
		
		routeService.setDefaultRoute(defaultRouteQuery, defaultRouteParams);
	},
	async [actionTypes.setReadMode]({ commit }) {
		commit(mutationTypes.SET_MODE, HrVacationPlansPageModeEnum.READ);
		commit(mutationTypes.SET_EDITABLE_PLAN_ID, "");
		commit(mutationTypes.RESET_EDITABLE_VACATION);
		commit(mutationTypes.SET_EDITABLE_VACATION_INDEX, null);
		commit(mutationTypes.SET_PARENT_VACATIONS, []);
	}
};

const mutations = <MutationTree<VacationPlansState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_IS_SAVING_AND_APPROVING](state, value) {
		state.isSavingAndApproving = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.REMOVE_CURRENT_PLAN](state) {
		const employee = state.listing.items.find(x => x.employee.id === first(state.filter.employeeIds))!;
		employee.vacationsPlans = cloneDeep(employee.vacationsPlans.filter(x => x.id !== state.filter.versionId));
	},
	[mutationTypes.SET_CURRENT_PLAN_STATE](state, { employeeId, value }) {
		const plans = state.listing.items.find(x => x.employee.id === employeeId)!.vacationsPlans;
		first(plans)!.state = value;
	},
	[mutationTypes.SET_FILTER_YEAR](state, value) {
		state.filter.year = value;
	},
	[mutationTypes.SET_FILTER_MONTH](state, value) {
		state.filter.month = value;
	},
	[mutationTypes.SET_FILTER_DEPARTMENT_IDS](state, value) {
		state.filter.departmentIds = value;
	},
	[mutationTypes.SET_FILTER_EMPLOYEE_IDS](state, value) {
		state.filter.employeeIds = value;
	},
	[mutationTypes.SET_FILTER_VERSION_ID](state, value) {
		state.filter.versionId = value;
	},
	[mutationTypes.SET_FILTER_VIEW](state, value) {
		state.filter.view = value;
	},
	[mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES](state, value) {
		state.filter.isAllEmployees = value;
	},
	[mutationTypes.SET_FILTER](state, value) {
		state.filter = value;
	},
	[mutationTypes.SET_IS_YEARS_LOADING](state, value) {
		state.isYearsLoading = value;
	},
	[mutationTypes.SET_IS_CALENDAR_LOADING](state, value) {
		state.isCalendarLoading = value;
	},
	[mutationTypes.SET_IS_DEPARTMENTS_LOADING](state, value) {
		state.isDepartmentsLoading = value;
	},
	[mutationTypes.SET_IS_GANTT_EMPLOYEES_LOADING](state, value) {
		state.isGanttEmployeesLoading = value;
	},
	[mutationTypes.SET_IS_EMPLOYEES_LOADING](state, value) {
		state.isEmployeesLoading = value;
	},
	[mutationTypes.SET_YEARS](state, value) {
		state.years = value;
	},
	[mutationTypes.SET_CALENDAR_DATES](state, value) {
		state.calendarDates = value;
	},
	[mutationTypes.SET_DEPARTMENTS](state, value) {
		state.departments = value;
	},
	[mutationTypes.SET_GANTT_EMPLOYEES](state, value) {
		state.ganttEmployees = value;
	},
	[mutationTypes.SET_EMPLOYEES](state, value) {
		state.employees = value;
	},
	[mutationTypes.SET_EDITABLE_VACATION_INDEX](state, value) {
		state.editableVacationIndex = value;
	},
	[mutationTypes.SET_EDITABLE_VACATION](state, value) {
		state.editableVacation = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_VACATION_START_DATE](state, value) {
		state.editableVacation.startDate = value;
	},
	[mutationTypes.SET_EDITABLE_VACATION_END_DATE](state, value) {
		state.editableVacation.endDate = value;
	},
	[mutationTypes.SET_EDITABLE_VACATION_PREVIOUS_VACATION_ID](state, value) {
		state.editableVacation.previousVacationId = value;
	},
	[mutationTypes.RESET_EDITABLE_VACATION](state) {
		state.editableVacation = HrNewVacationService.getEmpty();
	},
	[mutationTypes.SET_EDITABLE_PLAN_ID](state, value) {
		state.editableVersionId = value;
	},
	[mutationTypes.SET_IS_WITHDRAWING](state, value) {
		state.isWithdrawing = value;
	},
	[mutationTypes.SET_IS_DRAFT_DELETING](state, value) {
		state.isDraftDeleting = value;
	},
	[mutationTypes.SET_IS_SENDING_TO_REVIEW](state, value) {
		state.isSendingToReview = value;
	},
	[mutationTypes.SET_IS_APPROVING](state, value) {
		state.isApproving = value;
	},
	[mutationTypes.SET_IS_DECLINING](state, value) {
		state.isDeclining = value;
	},
	[mutationTypes.SET_IS_EXPORTING](state, value) {
		state.isExporting = value;
	},
	[mutationTypes.SET_NEW_VACATIONS](state, value) {
		state.newVacations = cloneDeep(value);
	},
	[mutationTypes.REMOVE_NEW_VACATIONS_ITEM](state, index) {
		state.newVacations.splice(index, 1);
	},
	[mutationTypes.ADD_NEW_VACATIONS_ITEM](state, value) {
		state.newVacations.push(cloneDeep(value));
	},
	[mutationTypes.SET_PARENT_VACATIONS](state, value) {
		state.parentVacations = cloneDeep(value);
	},
	[mutationTypes.SET_ACCEPT_PLAN_REQUEST](state, value) {
		state.acceptPlanRequest = cloneDeep(value);
	},
	[mutationTypes.RESET_ACCEPT_PLAN_REQUEST](state) {
		state.acceptPlanRequest = HrAcceptPlanRequestService.getEmpty();
	},
	[mutationTypes.SET_ACCEPT_PLAN_REQUEST_EMPLOYEE_ID](state, value) {
		state.acceptPlanRequest.employeeId = value;
	},
	[mutationTypes.SET_ACCEPT_PLAN_REQUEST_PLAN_ID](state, value) {
		state.acceptPlanRequest.planId = value;
	},
	[mutationTypes.SET_ACCEPT_PLAN_REQUEST_REASON](state, value) {
		state.acceptPlanRequest.reason = value;
	},
	[mutationTypes.SET_CURRENT_USER_PLANS](state, value) {
		state.currentUserPlans = cloneDeep(value);
	},
	[mutationTypes.SET_IS_NEW_VACATION_CREATING](state, value) {
		state.isNewVacationCreating = value;
	},
	[mutationTypes.SET_LOADING_APPROVAL_ID](state, value) {
		state.loadingApprovalId = value;
	},
	[mutationTypes.SET_APPROVAL](state, value) {
		state.approval = value;
	},
	[mutationTypes.SET_IS_APPROVER_REMOVING](state, value) {
		state.isApproverRemoving = value;
	},
	[mutationTypes.SET_IS_VACATION_PLAN_APPROVAL_DIALOG_OPENED](state, value) {
		state.isVacationPlanApprovalDialogOpened = value;
	},
	[mutationTypes.SET_OPENED_APPROVAL_VACATION_PLAN_NUMBER](state, value) {
		state.openedApprovalVacationPlanNumber = value;
	},
	[mutationTypes.SET_VACATION_REPLACEMENT_MIN_DATE](state, value) {
		state.vacationReplacementMinDate = value;
	},
	[mutationTypes.ADD_ITEMS_PLAN](state, { employeeId, value }) {
		const employeePlans = state.listing.items.find(x => x.employee.id === first(state.filter.employeeIds))!.vacationsPlans;
		employeePlans.unshift(cloneDeep(value));
	},
	[mutationTypes.SET_ITEMS_PLAN](state, { employeeId, planId, value }) {
		const employee = state.listing.items.find(x => x.employee.id === first(state.filter.employeeIds))!;
		
		employee.vacationsPlans = cloneDeep(employee.vacationsPlans.filter(x => x.id !== planId));
		employee.vacationsPlans.unshift(cloneDeep(value));
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const vacationPlansModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default vacationPlansModule;
