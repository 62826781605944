import { HrCreateVacationApplicationRequest } from "@/types/hr/vacationApplication/hrCreateVacationApplicationRequest";
import { convertToZonedIso, formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";
import { HrVacationApplication } from "@/types/hr/vacationApplication/hrVacationApplication";

export interface ApiHrGetSubstitutesParameters {
	dateStart: string;
	dateEnd: string;
}

export class ApiHrGetSubstitutesParametersService {
	static map(source: HrCreateVacationApplicationRequest): ApiHrGetSubstitutesParameters {
		return {
			dateStart: formatDate(source.startDate, isoDateFormat) as string,
			dateEnd: formatDate(source.endDate, isoDateFormat) as string
		};
	}
	
	static mapFromApplication(source: HrVacationApplication): ApiHrGetSubstitutesParameters {
		return {
			dateStart: formatDate(source.startDate, isoDateFormat) as string,
			dateEnd: formatDate(source.endDate, isoDateFormat) as string
		};
	}
}
